
/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/

$sans-serif: Arial,"Arial CE",Helvetica,"Helvetica CE",sans-serif;
$font: RobotoCondensedWeb;

$color-main: #D51100; //e81301
$color-dark: #303030;

/************************/