
/*******************
* NADPISY
*******************/

h1.cvi {
  font-size: rem(16px);
  padding: 5px 0 0 0;
  line-height: 1.1;
  margin: 0;
  position: absolute;
  left: 2px;
  top: 2px;
  font-weight: normal;
  color: #303030;

  strong {
    font-weight: normal;
    display: block;
  }
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
h4.ud,
#prohlaseni h3 {
	font-family: $font;
}

#prohlaseni h3 {
	margin-top: 15px;
}

h2.cvi,
.poz h2.cist {
	font-size: rem(30px);
  font-weight: bold;
  margin: 0;
  padding: 0;
  line-height: 1.2;
  text-transform: uppercase;
  color: #4a4a4a;

  &.bordered {
    color: #ffffff;
    border-bottom: 7px $color-main solid;
    text-align: center;

    span {
      display: inline-block;
      background: $color-main url(images/dots.svg) no-repeat 20px 50%;
      padding: 12px 44px 6px 44px;
    }
  }

  .box & {
    text-align: center;
    color: $color-dark;
    border-bottom: 2px $color-main solid;
    padding-bottom: 12px;
    margin-bottom: 28px;
  }
}


#zahlavi h2.cvi {
  margin: 20px 0 30px 0;
  padding: 0;
}

// Hlavní nadpisy na podstránce
#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
  font-size: rem(40px);
  color: $color-main;
  text-transform: none;
}

h3 /*{
	&.cvi,
	&.ud,
	#prohlaseni &*/ {
		font-size: rem(30px);
		font-weight: bold;
		margin: 25px 0 14px 0;
		padding: 8px 20px 4px 0;
		line-height: 1.2;
    color: #4a4a4a;

    &.ud {
      margin-top: 35px;
    }
	}
//}


.zobrazeno,
.vyhlodkaz {
  font-size: rem(14px);
}


h2.cvi.dotted.menu-heading {
  padding-left: 42px;
  background-position: 16px 50%;
  margin-bottom: 10px;
}

h4 {
	font-size: rem(24px);
	line-height: 1.2;
  font-family: $font;
  font-weight: normal;
}

h4.ud {
  font-size: rem(20px);
  
  + .udz {
    margin-top: 28px;    
  }
}


/**********************
* OSTATNÍ TEXTY
**********************/

form input,
form textarea,
form button,
form label,
form select {
	font-size: rem(14px);
}

#kalakci {
  .floatingBubbleElm p {
    font-size: rem(16px);
  }

  td,
  th {
    font-size: rem(15px);
  }
}

// .kontext2 h3 {
// 	font-size: rem(16px);
// }

.vzzalozky,
#rejstrik ul.rejstrikpismena {
	font-size: rem(17px);
}
