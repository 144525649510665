
/***********************
*	ZÁLOŽKY
***********************/


/***********************
* DEFINICE PROMĚNNÝCH
***********************/

$zalozkaTitulPozadi: transparent;
$zalozkaTitulText: #ffffff;
$zalozkaTitulAktivniPozadi: $color-main;
$zalozkaTitulAktivniText: #ffffff;

$zalozkaStrankaPozadi: $zalozkaTitulPozadi;
$zalozkaStrankaText: $color-dark;
$zalozkaStrankaAktivniPozadi: $color-main;
$zalozkaStrankaAktivniText: #ffffff;

/**********************/

.zalozky {
	font-size: rem(20px);
	line-height: 1.1;
	font-family: $font;
	font-weight: normal;

	ul {
		@extend %sf;
        @extend %reset-list;
	}

	li {
		padding: 0;
		margin: 0 .5em .5em 0;
		float: left;
		position: relative;
		display: block;

		&.azalozka {
			background:transparent none;
		}
	}

	a {
		display: block;
		text-decoration: none;
		margin: 0;
		padding: 8px 15px;
		position: relative;
		z-index: 2;
		cursor: pointer;
	}

	@include link {
		background-color: $zalozkaStrankaPozadi;
		color: $zalozkaStrankaText;
	}

	@include link-over {
		text-decoration: underline;
	}

	.azalozka {
		@include link-all {
			background-color: $zalozkaStrankaAktivniPozadi;
			color: $zalozkaStrankaAktivniText;
			text-decoration: none;
			cursor: default;
		}
	}
}

#zahlavi2 {
	margin-bottom: 30px;

	.zalozky {
		li {
			border: 2px $color-main solid;
		}
	}
}


#zalozkynadpis {
	padding: 15px 0 0 0;
}

.vych_pol {
	font-size: rem(16px);
	text-align: right;
	padding: 0 0 10px 0;
	margin: 0 0 10px 0;
	text-transform: uppercase;
	
	strong {
		font-weight: normal;
	}
}


a[id*="k0"] {
	height: 0;
	overflow: hidden;
	line-height: 0;
	font-size: 0;
	color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/

.bezjs {
	padding: 20px 0 15px 0;

	ul {
		@extend %reset-list;
	}

	.inline li::before {
		color: #404040;
	}
}

#keskryti {
	@extend %reset;
	display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/

#titul .zalozky {
	background: $color-dark url(images/bg-tabs.jpg) no-repeat 50% 0;
	background-size: cover;
	font-size: rem(30px);
	line-height: 1.1;
	padding-top: 50px;
	font-family: $font;
	text-align: center;
	text-transform: uppercase;
	border-bottom: 7px $color-main solid;

	.inline li::before {
		content: normal;
    }
    
    .inline{
        text-align: center;
        position: relative;
        display: flex;
        flex-wrap: wrap;

        .tv_trebic{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 5px;
            text-transform: uppercase;
            flex: 1;
            padding: 0;
            background: $color-main url(images/outer.png) no-repeat calc(100% - 20px) center;
            span{
                display: block;
                padding: 1rem 2.5em 0.75rem 1em;
            }
            &:hover,
            &:focus{
                background-color: $color-dark;
            }
        }
    }

    .centrovany{
        @include bp("> 1200px"){
            padding-left: 39px;
            padding-right: 39px;
        }
    }

	li {
		@extend %reset;
		float: none;
		margin: 0 5px;
        display: inline-block;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

		border: {
			style: solid;
			color: #e8e8e8;
			width: 2px 2px 0 2px;
		}
	}

	a {
		padding: 12px 34px 8px 34px;
        display: inline-block;
        display: block;
        width: 100%;
	}

	@include bp("< 1000px") {
		font-size: rem(24px);

		// a {
		// 	padding-right: 20px;
		// 	padding-left: 20px;
		// }
	}

	@include link {
		background-color: $zalozkaTitulPozadi;
		color: $zalozkaTitulText;
	}

	@include link-over {
		text-decoration: underline;
	}

	.azalozka {
		border-color: $color-main;
        background: $color-main;
		a {
			background: $color-main url(images/dots.svg) no-repeat 20px 50%;
		}

		@include link-all {
			background-color: $zalozkaTitulAktivniPozadi;
			color: $zalozkaTitulAktivniText;
		}
	}
}

#linkynakotvy{
	margin-bottom: 20px;
}

@include bp("< 650px") {
	#linkynakotvy .inline li{
		display: none;
	}

	#titul .poz {
		padding-top: 30px;

		h2.cist {
			position: static;
			width: auto;
			height: auto;
		}

		.skryty {
			display: block;
		}
	}
}
