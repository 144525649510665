
/*****************************************
* DEKLARACE PROMĚNNÝCH
*****************************************/

$menu-tablet-size:  800px;   // hranice mezi vyjížděcím/statickým menu
$menu-bg:           #ffffff; // pozadí menu
$menu-link-color:   $color-main; // barva odkazů a písma v menu
$menu-topbar-bg:    $color-main;    // barva horní lišty značící aktuální umístění 
$menu-topbar-text:  #ffffff; // text a ikony v horní liště
$menu-trigger-bg:   $color-main;   // barva pozadí tlačítka pro otevření menu 
$menu-trigger-text: #ffffff; // text v tlačítku
$menu-active-bg:    $color-dark;  // pozadí aktivní položky mobilního menu
$menu-active-text:  #ffffff;  // text aktivní položky mobilního menu

/****************/


/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/

$menu-spacing: 10px;
$menu-colors: #212922 #294936 #3e6259 #5b8266;


@mixin make-spacing( $amount ) {
	margin-left: -#{$amount};
	margin-right: -#{$amount};
}

@mixin make-row( $spacing ) {
	width: 100%;
	display: table;
	table-layout: fixed;
	border-collapse: separate;
	border-spacing: $menu-spacing 0;
}

@mixin make-columns {
	margin: 0;
	padding: 0;
	display: table-cell;
	list-style-type: none;
}

/****************************************/


/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/

#obalmenu1 {
	position: relative;
	z-index: 15;
	width: 100%;
	clear: both;
	background-color: #474747;
	
	@include bp("> 600px") {
		width: auto;
		clear: none;
		overflow: hidden;
		background-color: rgba(#000000, .7);
		border-radius: 4px;
	}
}

@include bp("> 500px", "<= 800px") {
	#menu1 {
		// ul {
		// 	@include flex-base;
		// 	@include flex-wrap(wrap);
		// }

		li {
			// @include box-sizing;
			// @include grid-column(2, 2%);
//			float: left;
			//margin-bottom: 15px;
		}
	}
}

#obalmenu1 {
	.navrat-menu {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		width: 169px;
		height: 45px;
		background: {
			color: #303030;
			repeat: no-repeat;
			position: 0 0;
			image: url(images/logo_menu.png);
		}
	}
}

@include bp("> 800px") {
	#obalmenu1 {
		.fixed-menu & {
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			padding-left: 180px;
			padding-right: 250px;
			border-radius: 0;
			z-index: 50;
			background: {
				color: #303030;
				repeat: no-repeat;
				position: 0 0;
				image: url(images/logo_menu.png);
			}

			.navrat-menu {
				display: block;
			}
		}
	}
}

#menu1 {
	padding: 0 42px;

	@include bp("<= 600px") {
		padding-left: 3%;
		padding-right: 3%;
	}

	ul {
		@extend %reset-list;
	}

	li {
		font-size: rem(23px);
		line-height: 1.1;
		margin: 0;

		@include bp("> 600px") {
			display: inline-block;

			&::before {
				content: "";
				vertical-align: middle;
				display: inline-block;
				width: 11px;
				height: 11px;
				border-radius: 100px;
				margin-top: -2px;
				background-color: $color-main;
			}

			// &:first-child::before {
			// 	content: normal;
			// }
		}

		// &.akt {
		// 	border: 5px #000 solid;
		// }

		div {
			display: inline-block;
		}

		a {
			display: inline-block;
			font-family: $font;
			padding: 16px 12px 18px 15px;

			#stranka & {
				padding-top: 11px;
				padding-bottom: 13px;
			}
		}

		.fixed-menu & {
			font-size: rem(20px);

			a {
				padding-top: 10px !important;
				padding-bottom: 13px;				
			}
		}

		@include link {
			text-decoration: none;
			color: #ffffff;
		}

		@include link-over {
			text-decoration: underline;
		}

		@include link-all("#osmakth") {
			cursor: text;
			text-decoration: none;
		}
	}
}


/*************************
* VÝBĚRY MENU
*************************/

// #vybrane {
// 	display: none;

// 	@include bp("> 800px") {
// 		display: block;
// 	}

// 	.bg {
// 		@include link {
// 			color: #ffffff;
// 			text-decoration: none;
// 		}
		
// 		@include link-over {
// 			text-decoration: underline;
// 		}
// 	}

// 	ul {
// 		list-style-type: none;
// 		margin: 0;
// 		padding: 15px 22px 10px 22px;
		
// 		li {
// 			padding: 0;
// 			display: inline;
// 		}

// 		.pam {
// 			font-size: rem(11px);
// 			border-left: 1px #ffffff solid;
// 			margin: 0 0px 0 9px;
// 			padding: 0;
// 			vertical-align: middle;
// 			position: relative;
// 			top: -1px;
// 		}

// 		.pampv .pam {
// 			display: none;
// 		}

// 		@include link(".pamdalsi a") {
// 			text-decoration: underline;
// 		}
// 	}
// }


/*********************
* MENU VE SLOUPCI
*********************/

#menu ul {
	@extend %reset-list;
}

#menu-control,
#menu-start {
	display: none;
}

.nadmenu,
#nadmenu {
	display: none;
}


/**********************
* MOBILNÍ MENU
**********************/

@include bp("< #{$menu-tablet-size}") {
	#menu {
		&.jsMenu,
		&.jsMenu ul {
			position: fixed;
			top: 0;
			left: 100vw;
			width: 100vw;
			height: 100vh;
			z-index: 100;
			@include transition( left .5s ease-out, top .5s, height .5s );
			border-top: 0;
		}

		#stranka & {
			padding-top: 0;
		}

		&.jsMenu {
			padding-top: 0;

			.html-menu & {
				left: 0;

				&:before {
					width: 200vw;
				}
			}

			ul {
				background-color: $menu-bg;
			  border-top: 56px transparent solid;
			  top: 0;
				height: 100vh;
				width: 1000vw;

				@include link {
					text-decoration: none;
					color: $menu-link-color;
				}

				&:before {
					@include transition( top .5s, height .5s );
					content: "";
					display: block;
					position: absolute;
					top: -56px;
					left: 0;
					right: 0;
					height: 56px;
				}

				li {
					width: 100vw;
					width: calc(100vw - 2px);
				}
			}

			.menu-active-mobile > ul {
				overflow-y: auto;
				overflow-x: visible;
				left: 0;
			}

			.menu-parent-mobile > ul {
				left: -100vw;
			}

			li.menu-current-path > a,
			a#osmakt {
				font-weight: normal !important;
				background-color: $menu-active-bg !important;
				color: $menu-active-text !important;
			}

			li,
			li li {
				padding: 0;

				span.menuobr {
//					@include transform(none);
					display: block;
					width: 17px;
					height: 17px;
					left: 20px;
					position: absolute;
					top: 50%;
					margin-top: -8.5px;

					img {
						border-radius: 50%;
					}
				}
			}

			li a {
				font-size: rem(22px);
				line-height: 1.1em;
				font-family: $font;
				text-transform: none;
				display: block;
				letter-spacing: 0.025em;
				padding: 10px 20px 10px 40px;
				position: relative;
				z-index: 0;
			}

			li li li {
				padding: 0;
			}

			li li ul {
				padding-left: 0;
			}

			li ul {
				padding: 0;
			}
		}
	}

	#menu-start {
		display: block;
		clear: both;
		background: $menu-trigger-bg;
		text-align: center;
		margin-bottom: 7px;

		a {
			display: inline-block;
			padding: 15px 0;

			&::before {
				display: inline-block;
				vertical-align: middle;
				margin: -.15em .75em 0 0;
				content: encode-svg('<svg xmlns="http://www.w3.org/2000/svg" width="30" height="22" viewBox="0 0 30 22"><path fill="#{$menu-trigger-text}" fill-rule="evenodd" clip-rule="evenodd" d="M29 22h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1z"/></svg>');
			}
		}

		@include link {
			color: $menu-trigger-text;
			text-decoration: none;
		}
	}

	#menu-control {
		@include transition( max-height .5s );
		position: relative;
		display: block;
		background-color: $menu-topbar-bg;
		padding: 0;
		z-index: 101;
		max-height: 56px;
		color: $menu-topbar-text;

		&::after {
			content: "";
			font-size: 0;
			display: table;
			clear: both;
		}

		@include link {
			color: $menu-topbar-text;
		}
	}

	#menu-control--back {
		@include box-sizing();
		font-size: rem(25px);
		line-height: 1.1em;
		font-family: $font;
		display: block;
		float: left;
		padding: 11px 10px 14px 20px;
		min-height: 56px;
		text-decoration: none;

		&:not(.root) {
			padding-left: 46px;
			background: {
				color: transparent;
				image: encode-svg('<svg width="15" height="22" xmlns="http://www.w3.org/2000/svg"><path fill="#{$menu-topbar-text}" d="M13.219 3.204l-7.832 7.72 7.832 7.719c.409.403.409 1.057 0 1.459l-1.465 1.443c-.409.404-1.071.404-1.479 0l-9.972-9.83c-.221-.216-.317-.507-.301-.791-.017-.284.08-.574.301-.792l9.972-9.831c.409-.403 1.071-.403 1.479 0l1.465 1.443c.409.404.409 1.057 0 1.46z"/></svg>');
				repeat: no-repeat;
				position: 20px center;
			}
		}
	}

	#menu-control--exit {
		display: block;
		float: right;
		text-align: left;
		padding: 15px 20px 0 20px;

		&::before {
			content: encode-svg('<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path fill="#{$menu-topbar-text}" fill-rule="evenodd" d="M13.569 10.994l8.162 8.165c.355.358.355.936 0 1.294l-1.28 1.279c-.357.358-.935.358-1.293 0l-8.16-8.166-8.162 8.166c-.357.358-.935.358-1.292 0l-1.278-1.279c-.357-.358-.357-.936 0-1.294l8.162-8.165-8.152-8.154c-.357-.357-.357-.935 0-1.292l1.279-1.28c.356-.357.935-.357 1.292 0l8.151 8.154 8.152-8.154c.357-.357.935-.357 1.293 0l1.276 1.28c.359.357.359.935 0 1.292l-8.15 8.154z"/></svg>');
		}
	}

	.html-menu {
		overflow: hidden;

		body {
			overflow: hidden;
			position: relative;
		}
	}
}


/***********************
* DESKTOPOVÉ MENU
***********************/

@include bp(">= #{$menu-tablet-size}") {
	.nadmenu,
	#nadmenu {
		display: block;
	}
	#nadmenu {
		font-size: rem(14px);
		display: block;
		font-weight: normal;
		padding: 12px 20px;
		white-space: nowrap;
	}

	#menu {
		background-color: $menu-bg;
		padding-top: 3px;

		&.jsMenu .menu ul {
			display: none;
		}

		&.jsMenu .menu .menu-active-pc > ul {
			display: block;
		}

		.menu {
			font-size: 1.3525rem;
			font-family: $font;
			line-height: 1.2;

			@include link {
				color: $menu-link-color;
			}

			@include link("ul a") {
				color: $color-dark;
			}

			@include link-over {
				color: $color-dark;
				text-decoration: underline;
			}

			@include link-over("ul a") {
				text-decoration: underline;
			}

			@include link-all("#osmakt") {
				color: $color-dark;
				text-decoration: none;
				cursor: default;
			}

			@include link-all("ul #osmakt") {
				font-weight: bold;
			}

			.menu-active-pc {
				> a .menuobr {
					@include transform(rotate(225deg));
				}

				> ul {
					display: block;
				}
			}

			a {
				padding: 10px 15px 2px 42px;
				display: block;
				position: relative;
				z-index: 10;
				text-decoration: none;
			}

			.menuobr {
				width: 17px;
				height: 17px;
				overflow: hidden;
				position: absolute;
				top: .7em;
				left: 11px;
				z-index: 10;
				-webkit-transition: -webkit-transform .25s;
				transition: -ms-transform .25s;
				transition: transform .25s;

				img {
					margin: 0;
					cursor: pointer;
				}
			}

			ul {
				font-size: rem(16px);
				font-family: $sans-serif;
				margin: 0;
				padding: 0 0 12px 42px;

				a {
					padding: 1px 32px 0 0;
					line-height: 1.5;
				}

				.menuobr {
					left: -31px;
					top: .25em;
				}

				ul {
					margin: 0;
					padding: 0 0 0 18px;
					position: static;
				}
			}
		}

		li {
			margin: 0;
			padding: 0;
			position: relative;
			//border-top: 1px #6C82A2 solid;
			display: block;
			
			&:first-child {
				border-top: 0;
			}

			li {
				border: 0;
				font-weight: normal;
				//background-position: 0 0.15em;

				// li {
				// 	padding: 1px 0;
				// }
			}

			// &.akt {
			// 	font-weight: bold;
			// 	text-decoration: none;
			// }
		}
	}
}
