
/*************************
* ZÁHLAVÍ
*************************/


/*************************
* HLAVA
*************************/
 
#obalhlava {
	@extend %sf;
	position: relative;
	z-index: 13;
	background: #ededed none/*url(images/bg-header.jpg)*/ no-repeat 50% 0;
	background-size: cover;
	color: #ffffff;

	@include link {
		color: #ffffff;
	}

	#stranka & {
		border-bottom: 7px $color-main solid;

		@include bp("> 600px") {
			background-image: url(images/bg-header-sub.jpg);
			background-position: 50% 100%;
		}
	}
}

#hlava {
	@extend %sf;
	clear: both;
	position: relative;
	z-index: 3;
	margin-bottom: 11%;

	@include bp("<= 600px") {
		width: 100%;
	}

	#stranka & {
		margin-bottom: 5%;
	}

	@include bp("> 1280px") {
		margin-bottom: 305px;

		#stranka & {
			margin-bottom: 80px;
		}
	}
}

#znak {
	margin: 0;
	position: relative;
	z-index: 2;
	color: #303030;
}


#nazev {
	text-align: left;
	position: relative;
	margin: 0 0 15px 0;

	@include bp("<= 600px") {
		margin-left: 3%;
	}

	a {
		z-index: 30;
		display: inline;
		text-decoration: none !important;
		outline-width: 0;
		font-size: 0;

		img {
			font-size: rem(16px);
			line-height: 1;
		}

		&::after {
			content: "";
			@extend %fill;
		}
	}
}

#znak,
#nazev,
#nazev a,
h1.cvi {
	float: left;
}

#nazev {
	margin-right: 75px;


	#stranka & {
		margin-left: 37px;
	}
}

/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/

#obalobrazek {
	display: none;
}

@include bp("> 600px") {
	#obalobrazek {
		display: block;
		clear: both;
	}

	#obrazek {
		position: relative;
		padding-bottom: ratio(1920px, 400px);
		// bez blenderu:
		background: #303030 url(images/blenderImages/0.jpg) no-repeat 0 0;
		background-size: cover;

		// s blenderem místo toho následující:

		// #blender {
		// 	@include fill;
		//  z-index: 1;
		// 	overflow:hidden;
		//	background: #303030 url(images/blenderImages/0.jpg) no-repeat 0 0;
		// 	background-size: cover;

		// 	div,
		// 	span,
		// 	a {
		// 		@include fill;
		//		width: auto !important;
		//		height: auto !important;
		// 		background-size: cover !important;
		// 		overflow: hidden;
		// 	}

		// 	img {
		// 		position: absolute;
		// 		left: 0;
		// 		top: 0;
		// 		width: 100%;
		// 		height: auto;
		// 	}
		// }
	}

	// #administraceobrazky {
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	//  z-index: 2
	// }

	// #obrlinky {
	// 	position: absolute;
	// 	z-index: 2;
	// 	bottom: 14px;
	// 	right: 25px;

	// 	ul {
	// 		margin: 0;
	// 		padding: 0;
	// 		list-style-type: none;

	// 		li {
	// 			display: inline;
	// 			padding: 0;
	// 			margin: 0 0 0 14px;
	// 		}
	// 	}

	// 	a {
	// 		display: inline-block;
	// 		font-size: 0;
	// 		line-height: 0;
	// 		width: 17px;
	// 		height: 17px;
	// 		border-radius: 100px;
	// 		background-color: #ffffff;
	// 		box-shadow: 1px 1px 0 rgba(#000000, .85) inset;

	// 		&.imagesBlender_link_active {
	// 			background-color: red;
	// 		}
	// 	}
	// }
}


#lista {
	@extend %sf;
	text-align: right;
	padding: 16px 0 14px 0;
	position: relative;
	min-height: 34px;

	@include bp("<= 600px") {
		margin-bottom: 25px;
		padding-left: 3%;
		padding-right: 3%;
		background-color: $color-dark;
	}

	ul {
		@extend %reset-list;
		position: relative;

		@include bp("> 400px") {
			margin-left: 42px;
		}

		&.inline {
			display: inline;
			float: right;
		}
	}

	li {
		margin: 0;
		white-space: nowrap;

		&.skip {
			position: absolute;
			display: block;
			top: 0 !important;
			right: 0 !important;
			max-width: 100%;
			padding: 1px;
			border: 0;

			a {
				text-align: center;
				border: 0;
				position: absolute;
				right: 0;
				top: -500px;
				z-index: 0;
				padding: 0 2px;
				background-color: #ffffff;
				color: #000000;
			}
			
			a:focus,
			a:active {
				z-index: 10 !important;
				position: absolute !important;
				right: 10px;
				top: 5px;
			}
		}
	}
}


// #jazyky {
// 	li {
// 		&::before {
// 			content: normal;
// 		}

// 		a,
// 		span {
// 			font-size: rem(17px);
// 			font-family: $font;
// 			font-weight: bold;
// 			display: inline-block;
// 			width: 2em;
// 			height: 2em;
// 			line-height: 1.9em;
// 			text-align: center;
// 			border-radius: 200px;
// 			margin-left: 9px;
// 		}

// 		a {
// 			background-color: #ffffff;
// 		}

// 		span {
// 			color: #ffffff;
// 			background-color: $color-main;
// 		}

// 		@include link {
// 			color: #444444;
// 		}
// 	}
// }

#obaljazyky{
	display: inline-block;
	margin: 0;
	
	span{
		font-weight: normal;

	}

	a{
		color: #ffffff!important;
		text-decoration: none;
		
		&:hover{
			text-decoration: underline;
		}
	}

	li {
		margin: 0 9px 0 0;
		padding: 7px 9px 0;
		background-color: $color-main;
		display: inline-block;
		border-radius: 3px;
		height: 34px;
		@extend %border-box;

		@include firefox-only{
			padding: 4px 9px 0;
		}

		@include bp("<= 500px"){
			margin-bottom: 12px;

			&:last-child{
				margin-right: 0;
			}
		}
	}	
}

#jazyky {
	.goog-te-menu2-item div,
	.goog-te-menu2-item:link div,
	.goog-te-menu2-item:visited div,
	.goog-te-menu2-item:active div {
		background-color: transparent;
	}

	.goog-te-menu2-item:hover div {
		background-color: transparent;
	}

	.goog-te-menu2-item-selected div,
	.goog-te-menu2-item-selected:link div,
	.goog-te-menu2-item-selected:visited div,
	.goog-te-menu2-item-selected:hover div,
	.goog-te-menu2-item-selected:active div {
		font-weight: normal;
	}

	a > div {
		padding: 0;

		.indicator {
			display: none;
		}
	}

	.text {
		font-size: 0;
		color: #ffffff;

		&::before {
			font-size: rem(14px);
			font-weight: 300;
		}

		&.cs::before {
			content: "CS";
		}

		&.en::before {
			content: "EN";
		}

		&.de::before {
			content: "DE";
		}
	}
	
	div {
		display: inline;
	}
}