@charset "UTF-8";
/*! ECCO SASS knihovna
    @version: 1.0.4 */
/*****************************************
* ECCO - GLOBAL VARIABLES
*****************************************/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/
.mesice, #hledani .btn, #titul .zalozky li, #keskryti, .galerie-2016 .ui li, #pocasi p, .obrodkazy li, body, #menu ul, #menu1 ul, .bezjs ul, .zalozky ul, .galerie-2016 .ui, .temata .ui, .titulodkazy .ui, .obrodkazy .ui, .dok ul, #lista ul, #volny-cas .two-thirds ul, #halves .ui {
  margin: 0;
  padding: 0;
}

#menu ul, #menu1 ul, .bezjs ul, .zalozky ul, .galerie-2016 .ui, .temata .ui, .titulodkazy .ui, .obrodkazy .ui, .dok ul, #lista ul, #volny-cas .two-thirds ul, #halves .ui {
  list-style-type: none;
}

#hledani label.label-skryty::after, .titulformular label.label-skryty::after, #nazev a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.galerie-2016 .ui li, .nahledy div > a, .odkazy .ui li li::before, .aktuality2 li.bezobr li::before, .utvary .ui li.o li::before, .kategorie .ui li li::before, .utvary .ui li li::before, .galerie .ui li li::before, #obaljazyky li, #nadpatou .box, #stred,
#vpravo,
#vlevo, .centrovany {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.zalozky ul::after, .galerie-2016 .ui::after, #pocasi::after, #lista::after, #hlava::after, #obalhlava::after, .centrovany::after {
  content: "";
  display: table;
  font-size: 0;
  clear: both;
}

/*****************************************
* ECCO - FLEXBOX
*****************************************/
.nahledy {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -moz-box-wrap: nowrap;
  -webkit-box-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flexbox-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.nahledy {
  -moz-box-wrap: wrap;
  -webkit-box-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flexbox-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/
/************************/
/*******************
* NADPISY
*******************/
h1.cvi {
  font-size: 1rem;
  padding: 5px 0 0 0;
  line-height: 1.1;
  margin: 0;
  position: absolute;
  left: 2px;
  top: 2px;
  font-weight: normal;
  color: #303030;
}
h1.cvi strong {
  font-weight: normal;
  display: block;
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
h4.ud,
#prohlaseni h3 {
  font-family: RobotoCondensedWeb;
}

#prohlaseni h3 {
  margin-top: 15px;
}

h2.cvi,
.poz h2.cist {
  font-size: 1.875rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  line-height: 1.2;
  text-transform: uppercase;
  color: #4a4a4a;
}
h2.cvi.bordered,
.poz h2.cist.bordered {
  color: #ffffff;
  border-bottom: 7px #D51100 solid;
  text-align: center;
}
h2.cvi.bordered span,
.poz h2.cist.bordered span {
  display: inline-block;
  background: #D51100 url(images/dots.svg) no-repeat 20px 50%;
  padding: 12px 44px 6px 44px;
}
.box h2.cvi,
.box .poz h2.cist {
  text-align: center;
  color: #303030;
  border-bottom: 2px #D51100 solid;
  padding-bottom: 12px;
  margin-bottom: 28px;
}

#zahlavi h2.cvi {
  margin: 20px 0 30px 0;
  padding: 0;
}

#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
  font-size: 2.5rem;
  color: #D51100;
  text-transform: none;
}

h3 {
  font-size: 1.875rem;
  font-weight: bold;
  margin: 25px 0 14px 0;
  padding: 8px 20px 4px 0;
  line-height: 1.2;
  color: #4a4a4a;
}
h3.ud {
  margin-top: 35px;
}

.zobrazeno,
.vyhlodkaz {
  font-size: 0.875rem;
}

h2.cvi.dotted.menu-heading {
  padding-left: 42px;
  background-position: 16px 50%;
  margin-bottom: 10px;
}

h4 {
  font-size: 1.5rem;
  line-height: 1.2;
  font-family: RobotoCondensedWeb;
  font-weight: normal;
}

h4.ud {
  font-size: 1.25rem;
}
h4.ud + .udz {
  margin-top: 28px;
}

/**********************
* OSTATNÍ TEXTY
**********************/
form input,
form textarea,
form button,
form label,
form select {
  font-size: 0.875rem;
}

#kalakci .floatingBubbleElm p {
  font-size: 1rem;
}
#kalakci td,
#kalakci th {
  font-size: 0.9375rem;
}

.vzzalozky,
#rejstrik ul.rejstrikpismena {
  font-size: 1.0625rem;
}

@font-face {
  font-family: "RobotoCondensedWeb";
  font-weight: normal;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/robotocondensed-regular-web.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/robotocondensed-regular-web.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/robotocondensed-regular-web.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "RobotoCondensedWeb";
  font-weight: 300;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/robotocondensed-light-web.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/robotocondensed-light-web.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/robotocondensed-light-web.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "RobotoCondensedWeb";
  font-weight: bold;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/robotocondensed-bold-web.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/robotocondensed-bold-web.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/robotocondensed-bold-web.ttf") format("truetype");
  font-display: swap;
}
html {
  line-height: 1.375;
  font-size: 18px;
}
@media screen and (min-width: 601px) {
  html {
    font-size: 16px;
  }
}

body.editor {
  background-color: #ffffff;
}
body.editor h3 {
  font-size: 1.375rem;
  font-family: RobotoCondensedWeb;
  font-weight: normal;
}

body,
.editor {
  font-size: 1em;
}

body,
.automat {
  color: #000000;
}

a,
a:link,
a:visited {
  color: #232323;
  text-decoration: underline;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

hr,
hr.oddelovac {
  color: #dddddd;
  background-color: #dddddd;
}

body,
input,
button,
textarea,
select {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
}

.zvyrazneni,
.vystraha,
.strlistovani strong {
  color: #BD013E;
  background-color: #ffffff;
  padding: 0 3px;
}

.vystraha {
  border-radius: 3px;
}

.zvyrazneni2 {
  background-color: #303030;
  color: #ffffff;
  padding: 0 2px;
}

strong.zprava {
  font-size: 1.1em;
}

img {
  max-width: 100%;
  height: auto;
}

h2.cvi.dotted,
h3,
.editor h3 {
  font-size: 30px;
  font-size: 1.875rem;
  color: #4a4a4a;
  font-weight: bold;
  font-family: RobotoCondensedWeb;
  text-transform: uppercase;
  padding: 5px 0 5px 26px;
  background: transparent url(images/dots-red.svg) no-repeat 0 50%;
}
h2.cvi.dotted a,
h2.cvi.dotted a:link,
h2.cvi.dotted a:visited,
h3 a,
h3 a:link,
h3 a:visited,
.editor h3 a,
.editor h3 a:link,
.editor h3 a:visited {
  color: #4a4a4a;
  text-decoration: none;
}
h2.cvi.dotted a:hover,
h2.cvi.dotted a:focus,
h2.cvi.dotted a:active,
h3 a:hover,
h3 a:focus,
h3 a:active,
.editor h3 a:hover,
.editor h3 a:focus,
.editor h3 a:active {
  text-decoration: underline;
}

.editor ul li {
  list-style-image: url(images/li-odr.png);
}
.editor ul li.typsouboru {
  list-style-image: none;
}

/**************************
* DEFINICE PROMĚNNÝCH
**************************/
/*************************/
/**************************
* OBECNÉ
**************************/
html,
body {
  background-color: #f3f3f3;
}

body {
  text-align: center;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.expandable):focus {
  border-radius: 3px;
  box-shadow: 0 0 0 2000px #303030 inset, 0 0 0 3px #303030 !important;
  color: #ffffff !important;
  outline: 0;
}

/*************************
* HLAVNÍ OBSAH
*************************/
.centrovany {
  width: 94%;
  max-width: 1280px;
  margin: 0 auto;
  text-align: left;
}
.centrovany--padded {
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;
}
@media screen and (min-width: 601px) {
  .centrovany--padded {
    width: 94%;
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 1025px) {
  .centrovany--padded {
    padding-left: 44px;
    padding-right: 44px;
  }
}

#stranka #obalcelek {
  background-color: #ffffff;
}

#stred,
#vpravo,
#vlevo {
  width: 100%;
  float: none;
  margin-bottom: 30px;
}

#vpravo,
#vlevo {
  word-wrap: break-word;
}

#pozicovani {
  padding-bottom: 20px;
}
@media screen and (min-width: 650px) {
  #pozicovani .skryty {
    display: none;
  }
}

.poz {
  padding-bottom: 52px;
}
.poz .dat {
  font-weight: bold;
}
.poz .dok li strong img {
  clear: both;
  margin-left: 0;
  margin-right: 0;
  float: none;
}
#pozicovani .poz .dok li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 599px) {
  #pozicovani .poz .dok li {
    width: 100%;
  }
}
@media screen and (min-width: 600px) and (max-width: 1023px) {
  #pozicovani .poz .dok li {
    width: 48%;
    margin-left: 4%;
  }
  #pozicovani .poz .dok li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  #pozicovani .poz .dok li {
    width: 30.6666666667%;
    margin-left: 4%;
  }
  #pozicovani .poz .dok li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
#projekty .poz .dok li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  margin-top: 42px;
}
@media screen and (max-width: 599px) {
  #projekty .poz .dok li {
    width: 100%;
  }
}
@media screen and (min-width: 600px) and (max-width: 1023px) {
  #projekty .poz .dok li {
    width: 48%;
    margin-left: 4%;
  }
  #projekty .poz .dok li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  #projekty .poz .dok li {
    width: 31.6666666667%;
    margin-left: 2.5%;
  }
  #projekty .poz .dok li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

/*************************************
* TABULKOVÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/
#stranka #telo {
  padding-top: 40px;
}

@media screen and (min-width: 801px) {
  #stranka #telo {
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    direction: rtl;
    width: 100%;
    margin-bottom: 60px;
  }
  #stranka #telo > * {
    direction: ltr;
  }
  #stranka #telo #stred,
#stranka #telo #vpravo,
#stranka #telo #vlevo {
    display: table-cell;
    vertical-align: top;
  }
  #stranka #telo #vlevo {
    width: 34.375%;
    padding: 0 20px 0 0;
  }
  #stranka #telo #stred {
    width: auto;
    border-left: 1px #a4a4a4 solid;
    padding-left: 6%;
  }
}
/*************************************
* FLOATOVANÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/
/* #telo,
#stred {
	@extend %sf;
}

@include bp(">= 800px") {
	#stred {
		float: left;
		width: ratio($max-width, 880px);

		#stranka & {
			float: right;
			background-color: #ffffff;
		}
	}

	#vpravo,
	#vlevo {
		width: ratio($max-width, 360px);
	}

	#vpravo {
		float: right;
	}

	#vlevo {
		float: left;
	}
} */
/************************
* PRVKY STRÁNKY
************************/
.cesta {
  font-size: 0.9375rem;
  padding: 0 0 10px 0;
  margin: 0;
  position: relative;
}
.cesta strong {
  font-weight: bold;
}

.box {
  padding: 25px 30px 50px 30px;
  margin: 0;
  border: 5px #ffffff solid;
}

.dalsi {
  margin: 15px 0 0 0;
  padding: 5px 0 0 0;
  text-align: center;
}
.dalsi a {
  padding: 8px 40px 9px 40px;
  display: inline-block;
  background-color: #303030;
  border-radius: 3px;
}
.dalsi a,
.dalsi a:link,
.dalsi a:visited {
  color: #ffffff;
  text-decoration: none;
}
.dalsi a:hover,
.dalsi a:focus,
.dalsi a:active {
  text-decoration: underline;
}

/************************
* ZÁPATÍ
************************/
#halves {
  position: relative;
  overflow: hidden;
  padding: 50px 0;
  color: #ffffff;
}
#halves h2.cvi {
  color: #ffffff;
  text-align: left;
  border-bottom: 0;
  margin-bottom: 15px;
}
#halves a,
#halves a:link,
#halves a:visited {
  color: #ffffff;
}
#halves .centrovany--padded {
  padding-left: 0;
  padding-right: 0;
}
#halves .half {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  padding: 30px 3%;
  background-color: #303030;
}
@media screen and (max-width: 619px) {
  #halves .half {
    width: 100%;
  }
}
@media screen and (min-width: 620px) {
  #halves .half {
    width: 47%;
    margin-left: 6%;
  }
  #halves .half:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
#halves .half + .half {
  background-color: #D51100;
}
@media screen and (min-width: 621px) {
  #halves {
    padding: 50px 70px;
  }
  #halves .half {
    padding: 0;
  }
  #halves::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 0;
    border-left: 999em #303030 solid;
    border-right: 999em #D51100 solid;
    -webkit-transform: translateX(-999em);
    -ms-transform: translateX(-999em);
    transform: translateX(-999em);
  }
}
#halves .ui {
  padding-top: 5px;
}
@media screen and (min-width: 701px) {
  #halves .ui {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 25px;
    -moz-column-gap: 25px;
    column-gap: 25px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}
#halves .ui li {
  margin-top: 0;
  margin-bottom: 12px;
}
#halves .ui li a {
  font-size: 18px;
  font-size: 1.125rem;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
}
#halves .ui li a,
#halves .ui li a:link,
#halves .ui li a:visited {
  text-decoration: none;
}
#halves .ui li a:hover,
#halves .ui li a:focus,
#halves .ui li a:active {
  text-decoration: underline;
}
#halves .centrovany {
  position: relative;
  z-index: 3;
}

#volny-cas,
#o-trebici {
  padding: 55px 0;
}
#volny-cas h2.cvi,
#o-trebici h2.cvi {
  margin-left: -26px;
  margin-bottom: 10px;
}

#volny-cas {
  background-color: #4b4b4b;
  background-repeat: no-repeat;
  background-position: 50% 0;
  color: #ffffff;
}
#volny-cas img {
  border-radius: 5px;
}
#volny-cas h2.cvi {
  color: #ffffff;
}
#volny-cas a,
#volny-cas a:link,
#volny-cas a:visited {
  color: #ffffff;
}
@media screen and (min-width: 601px) {
  #volny-cas {
    background-image: url(images/bg-volny-cas.jpg);
    background-size: cover;
  }
}
#volny-cas .two-thirds {
  text-align: center;
}
#volny-cas .two-thirds ul li {
  display: block;
  margin: 0 0 20px 0;
}
@media screen and (min-width: 801px) {
  #volny-cas .third,
#volny-cas .two-thirds {
    margin-bottom: 0;
  }
  #volny-cas .third {
    width: 22%;
    margin-top: 20px;
  }
}
@media screen and (min-width: 801px) and (min-width: 1100px) {
  #volny-cas .third {
    margin-top: 50px;
  }
}
@media screen and (min-width: 801px) {
  #volny-cas .two-thirds {
    width: 76%;
    text-align: right;
  }
}
@media screen and (min-width: 801px) and (min-width: 1100px) {
  #volny-cas .two-thirds {
    width: 70%;
  }
}
@media screen and (min-width: 801px) {
  #volny-cas .two-thirds img {
    border-radius: 5px;
  }
}
@media screen and (min-width: 801px) {
  #volny-cas .two-thirds ul {
    display: flex;
    justify-content: center;
  }
  #volny-cas .two-thirds ul li {
    display: inline-block;
    margin: 0 0 0 5%;
  }
}
@media screen and (min-width: 801px) and (min-width: 1200px) {
  #volny-cas .two-thirds ul li {
    margin: 0 0 0 9.5%;
  }
}
@media screen and (min-width: 801px) {
  #volny-cas .two-thirds ul li:first-child {
    margin-left: 0;
  }
}
@media screen and (min-width: 801px) {
  #volny-cas .two-thirds ul li a {
    display: inline-block;
  }
}
@media screen and (min-width: 801px) {
  #volny-cas .two-thirds .ul-one {
    margin-top: 0;
  }
  #volny-cas .two-thirds .ul-one li {
    margin: 0 0 40px 5%;
  }
}
@media screen and (min-width: 801px) and (min-width: 1200px) {
  #volny-cas .two-thirds .ul-one li {
    margin: 0 0 40px 9.5%;
  }
}

.third,
.two-thirds {
  margin-bottom: 20px;
}

@media screen and (min-width: 801px) {
  .third {
    float: left;
    width: 25%;
  }
  .two-thirds {
    float: right;
    width: 70%;
  }
}
#o-trebici {
  padding-bottom: 20px;
}
#o-trebici .two-thirds {
  font-size: 1.3125rem;
  line-height: 1.6;
}
#o-trebici .two-thirds p {
  margin-bottom: 1em;
}
#o-trebici .two-thirds a,
#o-trebici .two-thirds a:link,
#o-trebici .two-thirds a:visited {
  color: #d80000;
  text-decoration: underline;
}
#o-trebici .two-thirds a:hover,
#o-trebici .two-thirds a:focus,
#o-trebici .two-thirds a:active {
  text-decoration: none;
}
#o-trebici .third p {
  margin: 8px 0 2em 0;
}
#o-trebici .otr-big {
  font-size: 4.6875rem;
  color: #D51100;
  line-height: 1;
  font-weight: bold;
}

#nadpatou {
  background: #ededed;
  padding: 52px 0 30px 0;
}
#nadpatou .box {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  margin-bottom: 25px;
}
@media screen and (max-width: 899px) {
  #nadpatou .box {
    width: 100%;
  }
}
@media screen and (min-width: 900px) {
  #nadpatou .box {
    width: 32%;
    margin-left: 2%;
  }
  #nadpatou .box:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 901px) {
  #nadpatou .centrovany {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -moz-box-wrap: nowrap;
    -webkit-box-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flexbox-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

#obalpata {
  background-color: #ededed;
}

#pata {
  font-size: 1rem;
  text-align: center;
  padding: 40px 0 20px 0;
  color: #5c5c5c;
}
#pata a,
#pata a:link,
#pata a:visited {
  color: #5c5c5c;
}
#pata .copy {
  color: #000000;
  font-weight: bold;
}
#pata .webmaster,
#pata .inline {
  display: block;
  margin: 0 0 20px 0;
  padding: 0;
}
#pata .webmaster {
  font-size: 0.875rem;
}

.strlistovani img,
#listovani img {
  background-color: #bbbbbb;
  color: #000000;
}
.strlistovani .aktivni img,
#listovani .aktivni img {
  background-color: #555555 !important;
  color: #FFFFFF;
}
.strlistovani .aktivni:hover img, .strlistovani .aktivni:focus img,
#listovani .aktivni:hover img,
#listovani .aktivni:focus img {
  background-color: #bbbbbb !important;
  color: #000000;
}

.vip img {
  max-width: 170px;
}

#osobnost .vip,
.utvarkontakt .vip {
  width: 200px !important;
  padding-right: 0;
  text-align: right;
}
#osobnost .vip img,
.utvarkontakt .vip img {
  max-width: 170px;
}
#osobnost #kvlevo,
.utvarkontakt #kvlevo {
  margin-right: 0 !important;
}

@media screen and (min-width: 801px) {
  #osobnost .vip {
    text-align: right;
  }
  #osobnost .vip + #kvlevo {
    margin-right: 200px !important;
  }
  #osobnost #kvlevo .editor {
    float: left;
  }
}
.editor li ol {
  margin-left: 25px;
}
.editor li ol li {
  list-style-type: decimal;
  list-style-image: none;
}

/*************************
* ZÁHLAVÍ
*************************/
/*************************
* HLAVA
*************************/
#obalhlava {
  position: relative;
  z-index: 13;
  background: #ededed none no-repeat 50% 0;
  background-size: cover;
  color: #ffffff;
}
#obalhlava a,
#obalhlava a:link,
#obalhlava a:visited {
  color: #ffffff;
}
#stranka #obalhlava {
  border-bottom: 7px #D51100 solid;
}
@media screen and (min-width: 601px) {
  #stranka #obalhlava {
    background-image: url(images/bg-header-sub.jpg);
    background-position: 50% 100%;
  }
}

#hlava {
  clear: both;
  position: relative;
  z-index: 3;
  margin-bottom: 11%;
}
@media screen and (max-width: 600px) {
  #hlava {
    width: 100%;
  }
}
#stranka #hlava {
  margin-bottom: 5%;
}
@media screen and (min-width: 1281px) {
  #hlava {
    margin-bottom: 305px;
  }
  #stranka #hlava {
    margin-bottom: 80px;
  }
}

#znak {
  margin: 0;
  position: relative;
  z-index: 2;
  color: #303030;
}

#nazev {
  text-align: left;
  position: relative;
  margin: 0 0 15px 0;
}
@media screen and (max-width: 600px) {
  #nazev {
    margin-left: 3%;
  }
}
#nazev a {
  z-index: 30;
  display: inline;
  text-decoration: none !important;
  outline-width: 0;
  font-size: 0;
}
#nazev a img {
  font-size: 1rem;
  line-height: 1;
}
#nazev a::after {
  content: "";
}

#znak,
#nazev,
#nazev a,
h1.cvi {
  float: left;
}

#nazev {
  margin-right: 75px;
}
#stranka #nazev {
  margin-left: 37px;
}

/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/
#obalobrazek {
  display: none;
}

@media screen and (min-width: 601px) {
  #obalobrazek {
    display: block;
    clear: both;
  }
  #obrazek {
    position: relative;
    padding-bottom: 20.8333333333%;
    background: #303030 url(images/blenderImages/0.jpg) no-repeat 0 0;
    background-size: cover;
  }
}
#lista {
  text-align: right;
  padding: 16px 0 14px 0;
  position: relative;
  min-height: 34px;
}
@media screen and (max-width: 600px) {
  #lista {
    margin-bottom: 25px;
    padding-left: 3%;
    padding-right: 3%;
    background-color: #303030;
  }
}
#lista ul {
  position: relative;
}
@media screen and (min-width: 401px) {
  #lista ul {
    margin-left: 42px;
  }
}
#lista ul.inline {
  display: inline;
  float: right;
}
#lista li {
  margin: 0;
  white-space: nowrap;
}
#lista li.skip {
  position: absolute;
  display: block;
  top: 0 !important;
  right: 0 !important;
  max-width: 100%;
  padding: 1px;
  border: 0;
}
#lista li.skip a {
  text-align: center;
  border: 0;
  position: absolute;
  right: 0;
  top: -500px;
  z-index: 0;
  padding: 0 2px;
  background-color: #ffffff;
  color: #000000;
}
#lista li.skip a:focus,
#lista li.skip a:active {
  z-index: 10 !important;
  position: absolute !important;
  right: 10px;
  top: 5px;
}

#obaljazyky {
  display: inline-block;
  margin: 0;
}
#obaljazyky span {
  font-weight: normal;
}
#obaljazyky a {
  color: #ffffff !important;
  text-decoration: none;
}
#obaljazyky a:hover {
  text-decoration: underline;
}
#obaljazyky li {
  margin: 0 9px 0 0;
  padding: 7px 9px 0;
  background-color: #D51100;
  display: inline-block;
  border-radius: 3px;
  height: 34px;
}
@-moz-document url-prefix() {
  #obaljazyky li {
    padding: 4px 9px 0;
  }
}
@media screen and (max-width: 500px) {
  #obaljazyky li {
    margin-bottom: 12px;
  }
  #obaljazyky li:last-child {
    margin-right: 0;
  }
}

#jazyky .goog-te-menu2-item div,
#jazyky .goog-te-menu2-item:link div,
#jazyky .goog-te-menu2-item:visited div,
#jazyky .goog-te-menu2-item:active div {
  background-color: transparent;
}
#jazyky .goog-te-menu2-item:hover div {
  background-color: transparent;
}
#jazyky .goog-te-menu2-item-selected div,
#jazyky .goog-te-menu2-item-selected:link div,
#jazyky .goog-te-menu2-item-selected:visited div,
#jazyky .goog-te-menu2-item-selected:hover div,
#jazyky .goog-te-menu2-item-selected:active div {
  font-weight: normal;
}
#jazyky a > div {
  padding: 0;
}
#jazyky a > div .indicator {
  display: none;
}
#jazyky .text {
  font-size: 0;
  color: #ffffff;
}
#jazyky .text::before {
  font-size: 0.875rem;
  font-weight: 300;
}
#jazyky .text.cs::before {
  content: "CS";
}
#jazyky .text.en::before {
  content: "EN";
}
#jazyky .text.de::before {
  content: "DE";
}
#jazyky div {
  display: inline;
}

/***********************
*	TABULKY
***********************/
.blokposun {
  background-color: #eeeeee;
}

.seznam {
  border: 0;
}
.seznam th a,
.seznam th a:link,
.seznam th a:visited,
.seznam th a:hover,
.seznam th a:focus,
.seznam th a:active {
  color: #ffffff;
}
.seznam td,
.seznam th {
  border: 1px white solid;
  border-width: 0 1px 1px 0;
}
.seznam td:last-child,
.seznam th:last-child {
  border-right: 0;
}
.seznam th {
  font-size: 1.125rem;
  font-family: RobotoCondensedWeb;
  line-height: 1.1;
  font-weight: normal;
  background-color: #303030;
  color: #ffffff;
}
.seznam th a,
.seznam th a:link,
.seznam th a:visited {
  color: #ffffff;
}
.seznam td {
  color: black;
  border-width: 1px 1px 0 0;
}
.seznam .b1 td {
  border-color: #ffffff;
}
.seznam .b2 td, .seznam #isvs .hodnota td, #isvs .seznam .hodnota td {
  border-color: #ebebeb;
}

.b1 {
  background-color: #ebebeb;
}

.b2, #isvs .hodnota {
  background-color: #ffffff;
}

/*************************
* SEZNAMY
*************************/
/*************************
* DEFINICE PROMĚNNÝCH
*************************/
/************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/************************/
li {
  /* 	.ui & {
  		.kategorie &,
  		.utvary &,
  		.galerie & {
  			@include bullet;
  		}
  	}
   */
}
.odkazy .ui li li::before, .aktuality2 li.bezobr li::before, .utvary .ui li.o li::before, .kategorie .ui li li::before, .utvary .ui li li::before, .galerie .ui li li::before {
  position: absolute;
  left: 4px;
  top: 0.46em;
  display: inline-block;
  content: "";
  width: 7px;
  height: 7px;
  background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='7px' height='7px' viewBox='0 0 7 7'%3E%3Cpath fill='%23BCBCBC' stroke='%23898989' stroke-miterlimit='10' d='M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z'/%3E%3C/svg%3E") no-repeat 0 0;
  line-height: 0;
  overflow: hidden;
}
li.u, li.typsouboru {
  list-style-type: none !important;
}
li.u::before, li.typsouboru::before {
  content: normal !important;
}
.inline li {
  display: inline;
  list-style-type: none;
}
.inline li::before {
  position: relative;
  content: "|";
  margin: 0 10px 0 11px;
}
.inline li:first-child::before, .inline li.skip::before, .inline li.skip + li:not(.skip)::before {
  content: normal;
}

.ui li {
  clear: none;
  padding: 0;
  margin: 5px 0 8px 0;
}
.ui li strong {
  font-size: 1.4375rem;
  font-family: RobotoCondensedWeb;
  font-weight: normal;
}
#stranka .ui li strong + div {
  padding: 0;
  color: #666666;
}
@media screen and (min-width: 501px) {
  #stranka .ui li strong + div {
    margin: 0 0 0 1.25em;
    display: inline;
  }
}
.ui li a,
.ui li a:link,
.ui li a:visited {
  color: #D51100;
}
.ui li ul {
  padding-top: 4px;
}
.ui li ul ul {
  padding-top: 0;
}
.ui li li {
  margin: 5px 0;
  padding-left: 22px;
}
.ui li li::after {
  content: normal;
}
.ui li li strong {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 1rem;
}
.ui li li a,
.ui li li a:link,
.ui li li a:visited {
  color: #232323;
}
.ui div {
  font-weight: normal;
  margin-bottom: 2px;
  margin-top: 6px;
  padding-top: 0;
  padding-bottom: 0;
}

/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/
#isvs .hodnota li::before {
  content: normal !important;
}

/*********************
* VÝPISY DOKUMENTŮ
*********************/
.dok li {
  display: block;
  margin: 20px 0;
  padding: 0;
  color: #444444;
}
.dok .ktg {
  font-size: 0.9375rem;
}
.dok strong {
  font-weight: normal;
}
.dok strong img {
  margin: 3px 20px 14px 0;
  float: left;
}
@media screen and (max-width: 419px) {
  .dok strong img {
    float: none;
    display: table;
    margin-right: 0;
  }
}
.dok strong a {
  font-size: 1.4375rem;
  font-family: RobotoCondensedWeb;
  color: #D51100;
  line-height: 1.1;
}

#zpravodajstvi ul.inline {
  padding: 0;
  margin: 20px 0;
}

/*******************************
* PŘEHLED OSOB
*******************************/
.dok.osoby li img {
  display: none;
}
@media screen and (max-width: 419px) {
  .dok.osoby li {
    padding-left: 0;
  }
  .dok.osoby li strong img, .dok.osoby li.noface::before {
    float: none;
    display: table;
    margin: 0 0 10px 0;
  }
}

/*******************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ	
*******************************/
@media screen and (min-width: 1025px) {
  .obrodkazy.socialni-site .ui {
    margin-left: 62px;
  }
}
.obrodkazy li {
  display: block;
  margin-bottom: 13px;
  background-image: none;
  position: relative;
}
.obrodkazy li::before {
  content: normal !important;
}
.obrodkazy li a,
.obrodkazy li a:link,
.obrodkazy li a:visited {
  text-decoration: underline;
  color: #303030;
}
.obrodkazy li a:hover,
.obrodkazy li a:focus,
.obrodkazy li a:active {
  text-decoration: none;
}
.obrodkazy a {
  font-size: 1.3125rem;
  font-family: RobotoCondensedWeb;
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding-left: 60px;
  display: table;
  height: 1em;
  min-height: 48px;
}
.obrodkazy .mtext {
  display: table-cell;
  vertical-align: middle;
}
.obrodkazy strong {
  font-weight: normal;
}

/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/
#vypisakci h3.cvi {
  font-size: 1.5625rem;
  margin-bottom: 10px;
}
#vypisakci .dok li {
  display: table;
  margin: 14px 0;
  padding-bottom: 14px;
  width: 100%;
}
#vypisakci .dok li strong a,
#vypisakci .dok li strong a:link,
#vypisakci .dok li strong a:visited {
  color: orange;
}
#vypisakci .dok .datum,
#vypisakci .dok .prazdnyDatum {
  display: table-cell;
  vertical-align: top;
  width: 5.52em;
  text-align: center;
  padding: 0.26em 0;
  font-family: RobotoCondensedWeb;
}
#vypisakci .dok .den,
#vypisakci .dok .mesic {
  display: block;
  line-height: 1.2;
}
#vypisakci .dok .den {
  font-size: 1.375rem;
  font-weight: bold;
  background: gold;
  color: #101010;
  padding: 0.3em 0;
}
#vypisakci .dok .mesic {
  font-size: 1rem;
  background: #444444;
  color: #ffffff;
  text-transform: lowercase;
  padding: 0.5em 0;
}
#vypisakci .dok .denPredlozka,
#vypisakci .dok .rok,
#vypisakci .dok .den .tecka {
  position: absolute;
  left: -9999px;
  right: 9990px;
}
#vypisakci .dok .prazdnyDatum {
  background: transparent;
}
#vypisakci .dok .obsahAkce {
  display: table-cell;
  padding-right: 15px;
  padding-left: 15px;
}

/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/
.titulodkazy li {
  display: block;
  background-image: none;
  margin: 4px 0;
  padding: 0;
  position: relative;
}
.titulodkazy li::before {
  content: normal !important;
}
.titulodkazy li div {
  margin: 2px 0 0 0;
}
.titulodkazy strong {
  font-weight: normal;
}

.temata {
  background-color: #ededed;
  padding-top: 44px;
  padding-bottom: 44px;
}
.temata .ui li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 499px) {
  .temata .ui li {
    width: 100%;
  }
}
@media screen and (min-width: 500px) and (max-width: 899px) {
  .temata .ui li {
    width: 49%;
    margin-left: 2%;
  }
  .temata .ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 900px) {
  .temata .ui li {
    width: 23.5%;
    margin-left: 2%;
  }
  .temata .ui li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}
.temata .ui li strong {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: RobotoCondensedWeb;
  line-height: 1.15;
  display: block;
}
.temata .ui li strong + div {
  display: none;
}
@media screen and (min-width: 501px) {
  .temata .ui li strong + div {
    color: #353535;
    display: block;
    margin-left: 0;
    margin-top: 6px;
  }
}
.temata .ui li a,
.temata .ui li a:link,
.temata .ui li a:visited {
  color: #D51100;
  text-decoration: none;
}
.temata .ui li a:hover,
.temata .ui li a:focus,
.temata .ui li a:active {
  color: #D51100;
  text-decoration: underline;
}

.bodkazy .ui li {
  padding-left: 0;
}
.bodkazy .ui li.typsouboru {
  padding-left: 32px;
  background-position: 0 0.55em;
}

dl.kontakty ul.ui ul {
  margin-left: 0;
}

#nahore ul.ui li strong a,
dd ul.ui li strong a {
  font-size: 19px;
}

dd ul.ui li li strong a {
  font-size: 16px;
}

div#trdochazka {
  width: 15px;
  float: left;
  margin: 20px 6px 0 0;
}

span.trdochazka,
ul#popisdochazky li::before {
  display: block;
  width: 8px;
  height: 8px;
  float: left;
  margin: 4px 6px 0px 0px;
  padding: 0;
  line-height: 1%;
  font-size: 12%;
}

div#trdochazka.off,
span.trdochazka.off,
ul#popisdochazky li.nepritomen::before {
  background-color: red;
}

div#trdochazka.on,
span.trdochazka.on,
ul#popisdochazky li.pritomen::before {
  background-color: green;
}

ul#popisdochazky {
  padding-left: 0;
  margin-left: 0;
  list-style-type: none;
}
ul#popisdochazky li::before {
  content: "";
  margin-top: 6px;
}

li span.trdochazka {
  border-radius: 50%;
  position: relative;
  top: 3em;
  display: inline-block;
}

.utvary li.o {
  padding-left: 0;
}
.utvary li.o::before {
  content: normal !important;
}
.utvary li.o .trdochazka {
  width: 11px;
  height: 11px;
  margin-right: 9px;
}

.item--facebook .source,
.instaimg .source {
  font-family: RobotoCondensedWeb;
  font-weight: bold;
  text-transform: uppercase;
}

.item--facebook .source::before {
  color: #3b5998;
  content: "Facebook";
}

.instaimg .source::before {
  color: #c13584;
  content: "Instagram";
}

.seznam td {
  word-break: break-word;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .seznam td {
    max-width: 12em;
    -ms-word-wrap: break-word;
  }
}

/*************************************************
*	POČASÍ - BLUEBOARD (STŘEDNÍ VELIKOST, DVA DNY)
*************************************************/
#pocasi div {
  background-color: transparent !important;
}
#pocasi div div,
#pocasi div p {
  width: 100% !important;
}
#pocasi div div {
  padding: 0;
  margin-bottom: 0;
}
#pocasi div div div {
  width: 50% !important;
}
#pocasi p {
  font-size: 1rem !important;
  clear: both;
}
#pocasi a {
  clear: both;
  padding-top: 5px;
  width: auto !important;
  height: auto !important;
  background-color: transparent !important;
  text-align: left !important;
  padding-left: 35px !important;
}

/**********************
*	FOTOGALERIE
**********************/
.nahledy li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  margin-bottom: 15px;
}
@media screen and (max-width: 399px) {
  .nahledy li {
    width: 100%;
  }
}
@media screen and (min-width: 400px) and (max-width: 799px) {
  .nahledy li {
    width: 49%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 800px) and (max-width: 1023px) {
  .nahledy li {
    width: 32%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  .nahledy li {
    width: 23.5%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}
.nahledy a,
.nahledy a:link,
.nahledy a:visited {
  text-decoration: none;
}
.nahledy div {
  height: 100%;
  display: block;
}
.nahledy div > a {
  background: transparent;
  border: 2px #dcdcdc solid;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
  min-height: 200px;
}
.nahledy:not(.pridanytext) div > a {
  height: 100%;
}
.nahledy div > a:hover,
.nahledy div > a:focus,
.nahledy div > a:active {
  background: transparent;
  border-color: #303030;
}
.nahledy img {
  margin: 0 auto;
  width: 80%;
  height: auto;
}

/**********************
*	PŘEHLEDY GALERIÍ
**********************/
.galerie-2016 .ui {
  margin-bottom: 30px;
  margin-top: 20px;
}
.galerie-2016 .ui li {
  clear: none;
  margin-bottom: 35px;
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 399px) {
  .galerie-2016 .ui li {
    width: 100%;
  }
}
@media screen and (min-width: 400px) and (max-width: 1023px) {
  .galerie-2016 .ui li {
    width: 49%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  .galerie-2016 .ui li {
    width: 32%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
.galerie-2016 .ui li img {
  margin: 0 0 8px 0;
  float: none;
  display: table;
}
.galerie-2016 .ui li a {
  display: block;
}
.galerie-2016 .ui li.noimage a::before {
  content: "";
  display: block;
  height: 0;
  margin-bottom: 8px;
  padding-bottom: 60%;
  background: #cccccc url(/aspinclude/vismoweb5/html/noimage.svg) no-repeat 50% 50%;
}

/***********************
*	ZÁLOŽKY
***********************/
/***********************
* DEFINICE PROMĚNNÝCH
***********************/
/**********************/
.zalozky {
  font-size: 1.25rem;
  line-height: 1.1;
  font-family: RobotoCondensedWeb;
  font-weight: normal;
}
.zalozky li {
  padding: 0;
  margin: 0 0.5em 0.5em 0;
  float: left;
  position: relative;
  display: block;
}
.zalozky li.azalozka {
  background: transparent none;
}
.zalozky a {
  display: block;
  text-decoration: none;
  margin: 0;
  padding: 8px 15px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.zalozky a,
.zalozky a:link,
.zalozky a:visited {
  background-color: transparent;
  color: #303030;
}
.zalozky a:hover,
.zalozky a:focus,
.zalozky a:active {
  text-decoration: underline;
}
.zalozky .azalozka a,
.zalozky .azalozka a:link,
.zalozky .azalozka a:visited,
.zalozky .azalozka a:hover,
.zalozky .azalozka a:focus,
.zalozky .azalozka a:active {
  background-color: #D51100;
  color: #ffffff;
  text-decoration: none;
  cursor: default;
}

#zahlavi2 {
  margin-bottom: 30px;
}
#zahlavi2 .zalozky li {
  border: 2px #D51100 solid;
}

#zalozkynadpis {
  padding: 15px 0 0 0;
}

.vych_pol {
  font-size: 1rem;
  text-align: right;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}
.vych_pol strong {
  font-weight: normal;
}

a[id*=k0] {
  height: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
  color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/
.bezjs {
  padding: 20px 0 15px 0;
}
.bezjs .inline li::before {
  color: #404040;
}

#keskryti {
  display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/
#titul .zalozky {
  background: #303030 url(images/bg-tabs.jpg) no-repeat 50% 0;
  background-size: cover;
  font-size: 1.875rem;
  line-height: 1.1;
  padding-top: 50px;
  font-family: RobotoCondensedWeb;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 7px #D51100 solid;
}
#titul .zalozky .inline li::before {
  content: normal;
}
#titul .zalozky .inline {
  text-align: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
#titul .zalozky .inline .tv_trebic {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  text-transform: uppercase;
  flex: 1;
  padding: 0;
  background: #D51100 url(images/outer.png) no-repeat calc(100% - 20px) center;
}
#titul .zalozky .inline .tv_trebic span {
  display: block;
  padding: 1rem 2.5em 0.75rem 1em;
}
#titul .zalozky .inline .tv_trebic:hover, #titul .zalozky .inline .tv_trebic:focus {
  background-color: #303030;
}
@media screen and (min-width: 1201px) {
  #titul .zalozky .centrovany {
    padding-left: 39px;
    padding-right: 39px;
  }
}
#titul .zalozky li {
  float: none;
  margin: 0 5px;
  display: inline-block;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #e8e8e8;
  border-width: 2px 2px 0 2px;
}
#titul .zalozky a {
  padding: 12px 34px 8px 34px;
  display: inline-block;
  display: block;
  width: 100%;
}
@media screen and (max-width: 999px) {
  #titul .zalozky {
    font-size: 1.5rem;
  }
}
#titul .zalozky a,
#titul .zalozky a:link,
#titul .zalozky a:visited {
  background-color: transparent;
  color: #ffffff;
}
#titul .zalozky a:hover,
#titul .zalozky a:focus,
#titul .zalozky a:active {
  text-decoration: underline;
}
#titul .zalozky .azalozka {
  border-color: #D51100;
  background: #D51100;
}
#titul .zalozky .azalozka a {
  background: #D51100 url(images/dots.svg) no-repeat 20px 50%;
}
#titul .zalozky .azalozka a,
#titul .zalozky .azalozka a:link,
#titul .zalozky .azalozka a:visited,
#titul .zalozky .azalozka a:hover,
#titul .zalozky .azalozka a:focus,
#titul .zalozky .azalozka a:active {
  background-color: #D51100;
  color: #ffffff;
}

#linkynakotvy {
  margin-bottom: 20px;
}

@media screen and (max-width: 649px) {
  #linkynakotvy .inline li {
    display: none;
  }
  #titul .poz {
    padding-top: 30px;
  }
  #titul .poz h2.cist {
    position: static;
    width: auto;
    height: auto;
  }
  #titul .poz .skryty {
    display: block;
  }
}
/******************
* MODULY
******************/
#uvod {
  overflow: hidden;
  margin: 0 0 30px 0;
}
#uvod h3 {
  font-weight: normal;
  margin: 10px 0;
  padding: 0;
  font-family: RobotoCondensedWeb;
  line-height: 1.2;
  background: transparent;
}

#mbannery {
  font-size: 0.875rem;
  padding: 30px 0;
  margin: 0;
  color: #000000;
  background: #ffffff;
}
#mbannery .editor {
  text-align: center;
}
#mbannery .editor a,
#mbannery .editor a:link,
#mbannery .editor a:visited,
#mbannery .editor a:hover,
#mbannery .editor a:focus,
#mbannery .editor a:active {
  color: #000000 !important;
}
#mbannery li {
  margin-top: 0;
  margin-bottom: 0;
}

#jmeniny {
  padding: 6px 12px 2px 15px;
  text-align: left;
}
#jmeniny * {
  display: inline;
}

#kontakt {
  float: left;
  line-height: 1.875;
}
#kontakt address {
  display: block;
  font-style: normal;
  margin: 0 0 15px 0;
}
#kontakt .address {
  margin-bottom: 25px;
}
#kontakt .address,
#kontakt .uredni-hodiny {
  display: block;
  margin-bottom: 25px;
}
#kontakt .den-tydne {
  display: inline-block;
  min-width: 4.1em;
}
@media screen and (min-width: 1025px) {
  #kontakt .address,
#kontakt .uredni-hodiny {
    float: left;
    width: 46%;
    margin-bottom: 0;
  }
  #kontakt .uredni-hodiny {
    margin-left: 8%;
  }
}
#kontakt .no-bold {
  font-weight: normal;
}

#anketa p {
  padding: 0;
  margin: 0 0 1em 0;
}
#anketa ul {
  list-style-type: none;
  padding: 0;
  margin: 8px 0 5px 0;
}
#anketa ul li {
  margin-bottom: 8px;
}
#anketa .hlas {
  margin-top: 3px;
  z-index: 1;
}
#anketa .hlas div {
  height: 12px;
  background-color: #483E28;
}

#pocitadlo {
  margin: 15px 0 0 0;
  text-align: center;
  padding-bottom: 15px;
}
#pocitadlo ul.inline {
  padding-left: 0;
  display: inline;
}
#pocitadlo ul.inline li span {
  margin: 0 0 0 2px;
}

/*****************************
* FORMULÁŘE
*****************************/
/*****************************
* DEFINICE PROMĚNNÝCH
*****************************/
/****************************/
/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/
#hledani label, .titulformular label {
  line-height: 1.2;
  text-align: left;
  padding: 3px 2px;
  display: block;
}
#hledani label.label-skryty, .titulformular label.label-skryty {
  padding: 0 2px;
  position: relative;
}
#hledani label.label-skryty::after, .titulformular label.label-skryty::after {
  content: "";
  z-index: 2;
}

/****************************/
/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/
.format {
  -webkit-transition: border-color 0.15s ease-out;
  transition: border-color 0.15s ease-out;
  background-color: #ffffff;
  color: #000000;
  height: 40px;
  border-radius: 2px;
  line-height: 1.2142857143;
}
.format:focus {
  border-color: #8f8d8d;
}

.format,
fieldset {
  border: 2px #c7c6c6 solid;
}

:not(.fvlevo) > .fvyraz #hv {
  font-size: 1.1875rem;
  height: 50px;
  border-width: 2px;
}

textarea.format {
  min-height: 80px;
}

select.format[multiple] {
  height: 120px;
}

.btn {
  background-color: #303030;
  border: 0;
  color: #ffffff;
  height: 40px;
}

.fbtn .btn {
  padding: 0 2em;
  font-size: 1rem;
  border-radius: 2px;
}

/************************************************/
.fkont {
  margin-top: 20px;
}
.fkont form {
  padding: 21px 36px 23px 36px;
}
.fkont :not(.fvlevo) > .fvyraz label[for=hv] {
  font-size: 1rem;
}

.formular,
.fkont form,
.nastaveni {
  background-color: #eeeeee;
  color: #202020;
}
.formular a,
.formular a:link,
.formular a:visited,
.fkont form a,
.fkont form a:link,
.fkont form a:visited,
.nastaveni a,
.nastaveni a:link,
.nastaveni a:visited {
  color: #202020;
}

.fcesta,
.fcesta a, .fcesta a:link, .fcesta a:visited, .fcesta a:hover, selector:focus, selector:active {
  background-color: #303030;
  color: #ffffff;
}

/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/
.titulformular label.label-skryty::after {
  background: transparent url(images/frm_prekryti.png) repeat 0 0;
}
.titulformular .format {
  margin: 0;
  padding: 3px 15px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.titulformular .captchaformat {
  padding-right: 82px;
}
.titulformular img {
  position: absolute;
  right: 2px;
  top: 2px;
  margin: 0;
}
.titulformular .fbtn,
.titulformular .btn {
  clear: none;
  margin: 0;
}
.titulformular .fbtn {
  text-align: center;
  margin-top: 30px;
}
.titulformular .opiste {
  position: relative;
}
.titulformular p {
  padding: 0 0 10px 0;
  margin: 0;
  line-height: 1.5625;
}

/***************
*	HLEDÁNÍ
***************/
#hledani {
  overflow: hidden;
}
@media screen and (min-width: 501px) {
  #hledani {
    float: right;
    width: 200px;
  }
  .fixed-menu #hledani {
    position: fixed;
    z-index: 55;
    right: 15px;
    top: 5px;
  }
}
#hledani .pole {
  position: relative;
  z-index: 5;
}
#hledani label.label-skryty::after {
  background: transparent url(images/hl_prekryti.png) repeat 0 0;
}
#hledani input:focus {
  outline: 0;
}
#hledani .format {
  width: 100%;
  margin: 0;
  height: 33px;
  border-radius: 3px;
  text-indent: 0 !important;
  border-color: #ffffff !important;
  color: #000000;
  padding: 4px 40px 4px 10px !important;
  background-repeat: no-repeat;
  background-position: 0 -30% !important;
}
#hledani .btn {
  border: 0;
  background-color: transparent;
  position: absolute;
  z-index: 2;
  top: 6px;
  right: 10px;
  height: auto;
  color: #000000;
}

form.formular label {
  font-size: 1rem;
}
form.formular .lblok label:first-child:last-child {
  position: relative;
  top: 11px;
}
form.formular .lblok [type=checkbox] {
  margin-top: 0.25em;
  margin-left: 42px;
}
form.formular label + br + span {
  font-size: 0.9rem;
  font-style: italic;
}
form.formular .pblok input[type=file] {
  width: 100%;
}
form.formular .pblok input[type=file]::-webkit-file-upload-button {
  -webkit-appearance: none;
  appearance: none;
  background-color: #303030;
  border: 0;
  color: #ffffff;
  height: 36px;
  box-sizing: border-box;
  padding: 2px 10px;
  margin-right: 15px;
}
form.formular .pblok input[type=file]::file-selector-button {
  -webkit-appearance: none;
  appearance: none;
  background-color: #303030;
  border: 0;
  color: #ffffff;
  height: 36px;
  box-sizing: border-box;
  padding: 2px 10px;
  margin-right: 15px;
}
form.formular .pblok:first-child {
  padding-left: 3%;
}
form.formular .pblok:first-child > label:first-child {
  font-weight: bold;
}

/*******************************
* KALENDÁŘE
*******************************/
/*******************************
* DEFINICE PROMĚNNÝCH 
*******************************/
/******************************/
/* #kalakci {
	p {
		clear: both;
	} */
#kalakci {
  margin-bottom: 30px;
}

.mesice {
  display: table;
  width: 100%;
  line-height: 0.8;
  table-layout: fixed;
  border-spacing: 1px 0;
  margin-bottom: 10px;
}
.mesice + p {
  margin: 20px 0;
}
.mesice a {
  display: table-cell;
  padding: 1em 0;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
}
.mesice a.sipka {
  width: 14.2857142857%;
  font-family: RobotoCondensedWeb;
  position: relative;
}
.mesice a,
.mesice a:link,
.mesice a:visited {
  color: #ffffff;
  background-color: #4A4A4A;
}
.mesice a:hover,
.mesice a:focus,
.mesice a:active {
  background-color: #000000;
}

.calcells th {
  font-weight: normal;
  color: #000000;
  background-color: #d8cfaf;
  border-bottom: 10px transparent solid;
  background-clip: padding-box;
}
.calcells th,
.calcells td {
  padding: 7px 0;
}
.calcells td > a {
  padding: 7px 0;
  margin: -7px 0;
}
.calcells td,
.calcells td > a, .calcells td a:link, .calcells td a:visited {
  background-color: #270722;
  color: #ffffff;
}
.calcells td.notmnth {
  background-color: #d8cfaf;
  color: #000000;
}
.calcells td.notmnth > a, .calcells td.notmnth a:link, .calcells td.notmnth a:visited {
  background-color: #d8cfaf;
  color: #000000;
}
.calcells td.curdate {
  border: 2px #ffffff solid;
  padding: 0;
}
.calcells td.curdate > a {
  padding: 5px 0;
  margin: -2px 0;
}
.calcells td.wkday > a:hover, .calcells td.wkday a:focus, .calcells td.wkday a:active, .calcells td.notmnth > a:hover, .calcells td.notmnth a:focus, .calcells td.notmnth a:active {
  background-color: #ffffff;
  color: #000000;
}

#stranka .calcells th {
  color: #000000;
  background-color: #d8cfaf;
}
#stranka .calcells td.wkday,
#stranka .calcells td.wkday > a, #stranka .calcells td.wkday a:link, #stranka .calcells td.wkday a:visited, #stranka .calcells td.wkend,
#stranka .calcells td.wkend > a, #stranka .calcells td.wkend a:link, #stranka .calcells td.wkend a:visited {
  background-color: #270722;
  color: #ffffff;
}
#stranka .calcells td > a, #stranka .calcells td a:link, #stranka .calcells td a:visited {
  background-color: #d8cfaf;
  color: #000000;
}
#stranka .calcells td.curdate {
  border: 2px #ffffff solid;
  border-color: #ffffff;
}
#stranka .calcells td.wkday > a:hover, #stranka .calcells td.wkday a:focus, #stranka .calcells td.wkday a:active, #stranka .calcells td.wkend > a:hover, #stranka .calcells td.wkend a:focus, #stranka .calcells td.wkend a:active, #stranka .calcells td.notmnth > a:hover, #stranka .calcells td.notmnth a:focus, #stranka .calcells td.notmnth a:active {
  background-color: #ffffff;
  color: #000000;
}
#stranka .calcells td.cell_selected,
#stranka .calcells td.cell_selected > selector-link-all {
  background-color: #B0030C;
  color: #ffffff;
}

/********************************
* KALENDÁŘE NA PODSTRÁNKÁCH
********************************/
/* 
#stranka {
	#kalakci .calcells {
		th {
			color: $kalTitulBunkyMimoMesicText;
			background-color: $kalTitulBunkyMimoMesicPozadi;
		}

		td {
			&.wkday,
			&.wkend {
				&,
				> a, a:link, a:visited {
					background-color: $kalStrankaBunkyMesicPozadi;
					color: $kalStrankaBunkyMesicText;
				}
			}

			//&.notmnth {
			> a, a:link, a:visited {
				background-color: $kalStrankaBunkyMimoMesicPozadi;
				color: $kalStrankaBunkyMimoMesicText;
			}
			//}

			&.curdate {
				border: 2px $kalTitulAktualniObrys solid;
				border-color: $kalStrankaAktualniObrys;
			}

			&.wkday,
			&.wkend,
			&.notmnth {
				> a:hover, a:focus, a:active {
					background-color: $kalBunkyPodMysiPozadi;
					color: $kalBunkyPodMysiText;
				}
			}

			&.cell_selected {
				&,
				> selector-link-all {
					background-color: $kalStrankaVybranyDenPozadi;
					color: $kalStrankaVybranyDenText;
				}
			}
		}
	}
}

 */
/*****************************************
* DEKLARACE PROMĚNNÝCH
*****************************************/
/****************/
/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/
/****************************************/
/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/
#obalmenu1 {
  position: relative;
  z-index: 15;
  width: 100%;
  clear: both;
  background-color: #474747;
}
@media screen and (min-width: 601px) {
  #obalmenu1 {
    width: auto;
    clear: none;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
  }
}

#obalmenu1 .navrat-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 169px;
  height: 45px;
  background-color: #303030;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: url(images/logo_menu.png);
}

@media screen and (min-width: 801px) {
  .fixed-menu #obalmenu1 {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding-left: 180px;
    padding-right: 250px;
    border-radius: 0;
    z-index: 50;
    background-color: #303030;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: url(images/logo_menu.png);
  }
  .fixed-menu #obalmenu1 .navrat-menu {
    display: block;
  }
}
#menu1 {
  padding: 0 42px;
}
@media screen and (max-width: 600px) {
  #menu1 {
    padding-left: 3%;
    padding-right: 3%;
  }
}
#menu1 li {
  font-size: 1.4375rem;
  line-height: 1.1;
  margin: 0;
}
@media screen and (min-width: 601px) {
  #menu1 li {
    display: inline-block;
  }
  #menu1 li::before {
    content: "";
    vertical-align: middle;
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 100px;
    margin-top: -2px;
    background-color: #D51100;
  }
}
#menu1 li div {
  display: inline-block;
}
#menu1 li a {
  display: inline-block;
  font-family: RobotoCondensedWeb;
  padding: 16px 12px 18px 15px;
}
#stranka #menu1 li a {
  padding-top: 11px;
  padding-bottom: 13px;
}
.fixed-menu #menu1 li {
  font-size: 1.25rem;
}
.fixed-menu #menu1 li a {
  padding-top: 10px !important;
  padding-bottom: 13px;
}
#menu1 li a,
#menu1 li a:link,
#menu1 li a:visited {
  text-decoration: none;
  color: #ffffff;
}
#menu1 li a:hover,
#menu1 li a:focus,
#menu1 li a:active {
  text-decoration: underline;
}
#menu1 li #osmakth,
#menu1 li #osmakth:link,
#menu1 li #osmakth:visited,
#menu1 li #osmakth:hover,
#menu1 li #osmakth:focus,
#menu1 li #osmakth:active {
  cursor: text;
  text-decoration: none;
}

/*************************
* VÝBĚRY MENU
*************************/
/*********************
* MENU VE SLOUPCI
*********************/
#menu-control,
#menu-start {
  display: none;
}

.nadmenu,
#nadmenu {
  display: none;
}

/**********************
* MOBILNÍ MENU
**********************/
@media screen and (max-width: 799px) {
  #menu.jsMenu, #menu.jsMenu ul {
    position: fixed;
    top: 0;
    left: 100vw;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    -webkit-transition: left 0.5s ease-out, top 0.5s, height 0.5s;
    transition: left 0.5s ease-out, top 0.5s, height 0.5s;
    border-top: 0;
  }
  #stranka #menu {
    padding-top: 0;
  }
  #menu.jsMenu {
    padding-top: 0;
  }
  .html-menu #menu.jsMenu {
    left: 0;
  }
  .html-menu #menu.jsMenu:before {
    width: 200vw;
  }
  #menu.jsMenu ul {
    background-color: #ffffff;
    border-top: 56px transparent solid;
    top: 0;
    height: 100vh;
    width: 1000vw;
  }
  #menu.jsMenu ul a,
#menu.jsMenu ul a:link,
#menu.jsMenu ul a:visited {
    text-decoration: none;
    color: #D51100;
  }
  #menu.jsMenu ul:before {
    -webkit-transition: top 0.5s, height 0.5s;
    transition: top 0.5s, height 0.5s;
    content: "";
    display: block;
    position: absolute;
    top: -56px;
    left: 0;
    right: 0;
    height: 56px;
  }
  #menu.jsMenu ul li {
    width: 100vw;
    width: calc(100vw - 2px);
  }
  #menu.jsMenu .menu-active-mobile > ul {
    overflow-y: auto;
    overflow-x: visible;
    left: 0;
  }
  #menu.jsMenu .menu-parent-mobile > ul {
    left: -100vw;
  }
  #menu.jsMenu li.menu-current-path > a,
#menu.jsMenu a#osmakt {
    font-weight: normal !important;
    background-color: #303030 !important;
    color: #ffffff !important;
  }
  #menu.jsMenu li,
#menu.jsMenu li li {
    padding: 0;
  }
  #menu.jsMenu li span.menuobr,
#menu.jsMenu li li span.menuobr {
    display: block;
    width: 17px;
    height: 17px;
    left: 20px;
    position: absolute;
    top: 50%;
    margin-top: -8.5px;
  }
  #menu.jsMenu li span.menuobr img,
#menu.jsMenu li li span.menuobr img {
    border-radius: 50%;
  }
  #menu.jsMenu li a {
    font-size: 1.375rem;
    line-height: 1.1em;
    font-family: RobotoCondensedWeb;
    text-transform: none;
    display: block;
    letter-spacing: 0.025em;
    padding: 10px 20px 10px 40px;
    position: relative;
    z-index: 0;
  }
  #menu.jsMenu li li li {
    padding: 0;
  }
  #menu.jsMenu li li ul {
    padding-left: 0;
  }
  #menu.jsMenu li ul {
    padding: 0;
  }
  #menu-start {
    display: block;
    clear: both;
    background: #D51100;
    text-align: center;
    margin-bottom: 7px;
  }
  #menu-start a {
    display: inline-block;
    padding: 15px 0;
  }
  #menu-start a::before {
    display: inline-block;
    vertical-align: middle;
    margin: -0.15em 0.75em 0 0;
    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='22' viewBox='0 0 30 22'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' clip-rule='evenodd' d='M29 22h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1z'/%3E%3C/svg%3E");
  }
  #menu-start a,
#menu-start a:link,
#menu-start a:visited {
    color: #ffffff;
    text-decoration: none;
  }
  #menu-control {
    -webkit-transition: max-height 0.5s;
    transition: max-height 0.5s;
    position: relative;
    display: block;
    background-color: #D51100;
    padding: 0;
    z-index: 101;
    max-height: 56px;
    color: #ffffff;
  }
  #menu-control::after {
    content: "";
    font-size: 0;
    display: table;
    clear: both;
  }
  #menu-control a,
#menu-control a:link,
#menu-control a:visited {
    color: #ffffff;
  }
  #menu-control--back {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-size: 1.5625rem;
    line-height: 1.1em;
    font-family: RobotoCondensedWeb;
    display: block;
    float: left;
    padding: 11px 10px 14px 20px;
    min-height: 56px;
    text-decoration: none;
  }
  #menu-control--back:not(.root) {
    padding-left: 46px;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='15' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' d='M13.219 3.204l-7.832 7.72 7.832 7.719c.409.403.409 1.057 0 1.459l-1.465 1.443c-.409.404-1.071.404-1.479 0l-9.972-9.83c-.221-.216-.317-.507-.301-.791-.017-.284.08-.574.301-.792l9.972-9.831c.409-.403 1.071-.403 1.479 0l1.465 1.443c.409.404.409 1.057 0 1.46z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 20px center;
  }
  #menu-control--exit {
    display: block;
    float: right;
    text-align: left;
    padding: 15px 20px 0 20px;
  }
  #menu-control--exit::before {
    content: url("data:image/svg+xml;charset=utf8,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M13.569 10.994l8.162 8.165c.355.358.355.936 0 1.294l-1.28 1.279c-.357.358-.935.358-1.293 0l-8.16-8.166-8.162 8.166c-.357.358-.935.358-1.292 0l-1.278-1.279c-.357-.358-.357-.936 0-1.294l8.162-8.165-8.152-8.154c-.357-.357-.357-.935 0-1.292l1.279-1.28c.356-.357.935-.357 1.292 0l8.151 8.154 8.152-8.154c.357-.357.935-.357 1.293 0l1.276 1.28c.359.357.359.935 0 1.292l-8.15 8.154z'/%3E%3C/svg%3E");
  }
  .html-menu {
    overflow: hidden;
  }
  .html-menu body {
    overflow: hidden;
    position: relative;
  }
}
/***********************
* DESKTOPOVÉ MENU
***********************/
@media screen and (min-width: 800px) {
  .nadmenu,
#nadmenu {
    display: block;
  }
  #nadmenu {
    font-size: 0.875rem;
    display: block;
    font-weight: normal;
    padding: 12px 20px;
    white-space: nowrap;
  }
  #menu {
    background-color: #ffffff;
    padding-top: 3px;
  }
  #menu.jsMenu .menu ul {
    display: none;
  }
  #menu.jsMenu .menu .menu-active-pc > ul {
    display: block;
  }
  #menu .menu {
    font-size: 1.3525rem;
    font-family: RobotoCondensedWeb;
    line-height: 1.2;
  }
  #menu .menu a,
#menu .menu a:link,
#menu .menu a:visited {
    color: #D51100;
  }
  #menu .menu ul a,
#menu .menu ul a:link,
#menu .menu ul a:visited {
    color: #303030;
  }
  #menu .menu a:hover,
#menu .menu a:focus,
#menu .menu a:active {
    color: #303030;
    text-decoration: underline;
  }
  #menu .menu ul a:hover,
#menu .menu ul a:focus,
#menu .menu ul a:active {
    text-decoration: underline;
  }
  #menu .menu #osmakt,
#menu .menu #osmakt:link,
#menu .menu #osmakt:visited,
#menu .menu #osmakt:hover,
#menu .menu #osmakt:focus,
#menu .menu #osmakt:active {
    color: #303030;
    text-decoration: none;
    cursor: default;
  }
  #menu .menu ul #osmakt,
#menu .menu ul #osmakt:link,
#menu .menu ul #osmakt:visited,
#menu .menu ul #osmakt:hover,
#menu .menu ul #osmakt:focus,
#menu .menu ul #osmakt:active {
    font-weight: bold;
  }
  #menu .menu .menu-active-pc > a .menuobr {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
  }
  #menu .menu .menu-active-pc > ul {
    display: block;
  }
  #menu .menu a {
    padding: 10px 15px 2px 42px;
    display: block;
    position: relative;
    z-index: 10;
    text-decoration: none;
  }
  #menu .menu .menuobr {
    width: 17px;
    height: 17px;
    overflow: hidden;
    position: absolute;
    top: 0.7em;
    left: 11px;
    z-index: 10;
    -webkit-transition: -webkit-transform 0.25s;
    transition: -ms-transform 0.25s;
    transition: transform 0.25s;
  }
  #menu .menu .menuobr img {
    margin: 0;
    cursor: pointer;
  }
  #menu .menu ul {
    font-size: 1rem;
    font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
    margin: 0;
    padding: 0 0 12px 42px;
  }
  #menu .menu ul a {
    padding: 1px 32px 0 0;
    line-height: 1.5;
  }
  #menu .menu ul .menuobr {
    left: -31px;
    top: 0.25em;
  }
  #menu .menu ul ul {
    margin: 0;
    padding: 0 0 0 18px;
    position: static;
  }
  #menu li {
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
  }
  #menu li:first-child {
    border-top: 0;
  }
  #menu li li {
    border: 0;
    font-weight: normal;
  }
}
#google_translate_element {
  display: none;
}