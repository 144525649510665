
/******************
* MODULY
******************/

#uvod {
	overflow: hidden;
	margin: 0 0 30px 0;

	h3 {
		font-weight: normal;
		margin: 10px 0;
		padding: 0;
		font-family: $font;
		line-height: 1.2;
		background: transparent;
	}
}

#mbannery {
	font-size: rem(14px);
	padding: 30px 0;
	margin: 0;
	color: #000000;
	background: #ffffff;

	.editor {
		text-align: center;

		@include link-all {
			color: #000000 !important;
		}
	}
	
	li { 
		margin-top: 0;
		margin-bottom: 0;
	}

	// hr.grafika {
	// 	position: absolute;
	// 	width: 100%;
	// 	left: 0;
	// 	top: 0;
	// }
}

#jmeniny {
	padding: 6px 12px 2px 15px;
	text-align: left;
	
	* {
		display: inline;
	}
}

#kontakt {
	float: left;
	line-height: ( 30 / 16 );

	address {
		display: block;
		font-style: normal;
		margin: 0 0 15px 0;
	}

	.address {
		margin-bottom: 25px;
	}

	.address,
	.uredni-hodiny {
		display: block;
		margin-bottom: 25px;
	}

	.den-tydne {
		display: inline-block;
		min-width: 4.1em;
	}

	@include bp("> 1024px") {
		.address,
		.uredni-hodiny {
			float: left;
			width: 46%;
			margin-bottom: 0;
		}

		.uredni-hodiny {
			margin-left: 8%; 
		}
	}
	
	.no-bold {
		font-weight: normal;
	}
}

#anketa {
	p {
		padding: 0;
		margin: 0 0 1em 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 8px 0 5px 0;

		li {
			margin-bottom: 8px;
		}
	}

	.hlas {
		margin-top: 3px;
		z-index: 1;
		
		div {
			height: 12px;
			background-color: #483E28;
		}
	}
}

#pocitadlo {
	margin: 15px 0 0 0;
	text-align: center;
	padding-bottom: 15px;

	ul.inline {
		padding-left: 0;
		display: inline;

		li span {
			margin: 0 0 0 2px;
		}
	}
}
