
/***********************
*	TABULKY
***********************/


$b2-background: #ffffff;
$b1-background: #ebebeb;
$th-background: $color-dark;
$th-text:       #ffffff;


.blokposun {
	background-color: #eeeeee;
}

.seznam {
	border: 0;
	
	@include link-all("th a") {
		color: #ffffff;
	}

	td,
	th {
		border: 1px white solid;
		border-width: 0 1px 1px 0;

		&:last-child {
			border-right: 0;
		}
	}

	th {
		font-size: rem(18px);
		font-family: $font;
		line-height: 1.1;
		font-weight: normal;
		background-color: $th-background;
		color: $th-text;

		@include link {
			color: $th-text;
		}
	}

	td {
		color: black;
		border-width: 1px 1px 0 0;
	}

	.b1 td {
		border-color: $b2-background;
	} 

	.b2 td {
		border-color: $b1-background;
	} 
}

.b1 {
	background-color: $b1-background;
} 

.b2 {
	background-color: $b2-background;
}
