
/**********************
*	FOTOGALERIE
**********************/

.nahledy {
	@extend %flex;
	@extend %flex-wrap;

	li {
		@include grid( 400px 2 2%, 800px 3, 1024px 4 );
		margin-bottom: 15px;
	}

	@include link {
		text-decoration: none;
	}

	div {
		height: 100%;
		display: block;

		> a {
			@extend %border-box;
			background: transparent;
			border: 2px #dcdcdc solid;
			@include transition(border-color .2s);
			min-height: 200px;
		}
	}

	&:not(.pridanytext) div > a {
		height: 100%;
	}

	@include link-over("div > a") {
		background: transparent;
		border-color: $color-dark;
	}

	img {
		margin: 0 auto;
		width: 80%;
		height: auto;
	}
}


/**********************
*	PŘEHLEDY GALERIÍ
**********************/

.galerie-2016 {
	.ui {
		@extend %sf;
		@extend %reset-list;
		margin-bottom: 30px;
		margin-top: 20px;

		li {
			@extend %border-box;
			@extend %reset;
			clear: none;
			margin-bottom: 35px;
			@include grid(400px 2 2%, 1024px 3);

			img {
				margin: 0 0 8px 0;
				float: none;
				display: table;
			}

			a {
				display: block;
			}

			&.noimage {
				a::before {
					content: "";
					display: block;
					height: 0;
					margin-bottom: 8px;
					padding-bottom: 60%;
					background: #cccccc url(/aspinclude/vismoweb5/html/noimage.svg) no-repeat 50% 50%;
				}
			}
		}
	}
}