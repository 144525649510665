
/*************************************************
*	POČASÍ - BLUEBOARD (STŘEDNÍ VELIKOST, DVA DNY)
*************************************************/

#pocasi {
	@extend %sf;

	div {
		background-color: transparent !important;

		div,
		p {
			width: 100% !important;
		}

		div {
			padding: 0;
			margin-bottom: 0;

			div {
				width: 50% !important;
			}
		}
	}

	p {
		font-size: rem(16px) !important;
		@extend %reset;
		clear: both;
	}

	a {
		clear: both;
		padding-top: 5px;
		width: auto !important;
		height: auto !important;
		background-color: transparent !important;
		text-align: left !important;
		padding-left: 35px !important;
	}
}
