// Sablona - vsechny stranky + FTB

@include font($font);

$font-base-px: 16px;
$font-base: get-base-font-size-percentage($font-base-px) !global;

html {
	line-height: ratio($font-base-px, 22px, float);
	font-size: 18px;

	@include bp("> 600px") {
		font-size: $font-base-px;
	}
}

body.editor {
	background-color: #ffffff;

	h3 {
		font-size: rem(22px);
		font-family: $font;
		font-weight: normal;
	}
}

body, 
.editor {
	font-size: 1em;
}

body,
.automat {
	color: #000000;
}

@include link {
	color: #232323;
	text-decoration: underline;
}

@include link-over {
	text-decoration: none;
}

hr,
hr.oddelovac {
	color: #dddddd;
	background-color: #dddddd;
}

body,
input,
button,
textarea,
select {
	font-family: $sans-serif;
}

.zvyrazneni,
.vystraha,
.strlistovani strong {
	color: #BD013E;
	background-color: #ffffff;
	padding: 0 3px;
}

.vystraha {
	border-radius: 3px;
}

.zvyrazneni2 {
	background-color: #303030;
	color: #ffffff;
	padding: 0 2px;
}

strong.zprava {
	font-size: 1.1em;
}

img {
	max-width: 100%;
	height: auto;
}


h2.cvi.dotted,
h3,
.editor h3 {
  @include font-size(30px);
  color: #4a4a4a;
  font-weight: bold;
  font-family: $font;
  text-transform: uppercase;
  padding: 5px 0 5px 26px;
  background: transparent url(images/dots-red.svg) no-repeat 0 50%;

  @include link {
    color: #4a4a4a;
    text-decoration: none;
  }

  @include link-over {
    text-decoration: underline;
  }
}

.editor ul li {
	list-style-image: url(images/li-odr.png);

	&.typsouboru {
		list-style-image: none;
	}
}