// Sablona - vsechny stranky

/**************************
* DEFINICE PROMĚNNÝCH
**************************/

$max-width: 1280px;

$link-focus-bg: #303030;
$link-focus-text: #ffffff;

/*************************/

/**************************
* OBECNÉ
**************************/

html,
body {
	background-color: #f3f3f3;
}

body {
	@extend %reset;
	text-align: center;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.expandable):focus {
	border-radius: 3px;
	box-shadow: 0 0 0 2000px $link-focus-bg inset, 0 0 0 3px $link-focus-bg !important;
	color: $link-focus-text !important;
	outline: 0;
}


/*************************
* HLAVNÍ OBSAH
*************************/

.centrovany {
	@extend %border-box;
	@extend %sf;
	width: 94%;
	max-width: $max-width;
	margin: 0 auto;
	text-align: left;

	&--padded {
		width: 100%;
		padding-left: 3%;
		padding-right: 3%;

		@include bp("> 600px") {
			width: 94%;
			padding-left: 24px;
			padding-right: 24px;
		}

		@include bp("> 1024") {
			padding-left: 44px;
			padding-right: 44px;
		}
	}
}

#obalcelek {
	#stranka & {
		background-color: #ffffff;
	}
}

#stred,
#vpravo,
#vlevo {
	@extend %border-box;
	width: 100%;
	float: none;
	margin-bottom: 30px;
}

#vpravo,
#vlevo {
	word-wrap: break-word;
}

#pozicovani {
	padding-bottom: 20px;

	@include bp(">= 650px") {
		.skryty {
			display: none;
		}
	}
}

.poz {
	padding-bottom: 52px;

	.dat {
		font-weight: bold;
	}

	.dok li strong img {
		clear: both;
		margin-left: 0;
		margin-right: 0;
		float: none;
	}

	#pozicovani & .dok li {
		@include grid(600px 2 4, 1024px 3);
	}

	#projekty & .dok li {
		@include grid(600px 2 4, 1024px 3 2.5);
		margin-top: 42px;
	}
}


/*************************************
* TABULKOVÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/

#stranka #telo {
	padding-top: 40px;
}

@include bp("> 800px") {
	#stranka #telo {
		display: table;
		table-layout: fixed;
		border-spacing: 0;
		direction: rtl;
		width: 100%;
		margin-bottom: 60px;

		> * {
			direction: ltr;
		}

		#stred,
		#vpravo,
		#vlevo {
			display: table-cell;
			vertical-align: top;
		}

		#vlevo {
			width: ratio($max-width, 440px);
			padding: 0 20px 0 0;
		}

		#stred {
			width: auto;
			border-left: 1px #a4a4a4 solid;
			padding-left: 6%;
		}
	}
}


/*************************************
* FLOATOVANÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/

/* #telo,
#stred {
	@extend %sf;
}

@include bp(">= 800px") {
	#stred {
		float: left;
		width: ratio($max-width, 880px);

		#stranka & {
			float: right;
			background-color: #ffffff;
		}
	}

	#vpravo,
	#vlevo {
		width: ratio($max-width, 360px);
	}

	#vpravo {
		float: right;
	}

	#vlevo {
		float: left;
	}
} */


/************************
* PRVKY STRÁNKY
************************/

.cesta {
	font-size: rem(15px);
	padding: 0 0 10px 0;
	margin: 0;
	position: relative;

	strong {
		font-weight: bold;
	}
}

.box {
	padding: 25px 30px 50px 30px;
	margin: 0;
	border: 5px #ffffff solid;
}

.dalsi {
	margin: 15px 0 0 0;
	padding: 5px 0 0 0;
	text-align: center;

	a {
		padding: 8px 40px 9px 40px;
		display: inline-block;
		background-color: $color-dark;
		border-radius: 3px;
	}

	@include link {
		color: #ffffff;
		text-decoration: none;
	}

	@include link-over {
		text-decoration: underline;
	}
}


/************************
* ZÁPATÍ
************************/

#halves {
	position: relative;
	overflow: hidden;
	padding: 50px 0;
	color: #ffffff;

	h2.cvi {
		color: #ffffff;
		text-align: left;
		border-bottom: 0;
		margin-bottom: 15px;
	}

	@include link {
		color: #ffffff;
	}

	.centrovany--padded {
		padding-left: 0;
		padding-right: 0;
	}

	.half {
		@include box-sizing;
		@include grid(620px 2 6);
		padding: 30px 3%;
		background-color: $color-dark;

		+ .half {
			background-color: $color-main;
		}
	}

	@include bp("> 620px") {
		padding: 50px 70px;

		.half {
			padding: 0;
		}

		&::before {
			content: "";
			position: absolute;
			left: 50%;
			top: 0;
			bottom: 0;
			width: 0;
			border-left: 999em $color-dark solid;
			border-right: 999em $color-main solid;
			@include transform(translateX(-999em));
		}
	}

	.ui {
		@extend %reset-list;
		padding-top: 5px;

		@include bp("> 700px") {
			@include column-count(2);
			@include column-gap(25px);
			@include break-inside(avoid);
		}

		li {
			margin-top: 0;
			margin-bottom: 12px;

			a {
				@include font-size(18px);
				font-family: $sans-serif;
			}

			@include link {
				text-decoration: none;
			}

			@include link-over {
				text-decoration: underline;
			}
		}
	}

	.centrovany {
		position: relative;
		z-index: 3;
	}
}


#volny-cas,
#o-trebici {
		padding: 55px 0;

		h2.cvi {
			margin-left: -26px;
			margin-bottom: 10px;
		}
}

#volny-cas {
	background-color: #4b4b4b;
	background-repeat: no-repeat;
	background-position: 50% 0;
	color: #ffffff;

    img {
        border-radius: 5px;
    }

	h2.cvi {
		color: #ffffff;
	}

	@include link {
		color: #ffffff;
	}

	@include bp("> 600px") {
		background-image: url(images/bg-volny-cas.jpg);
		background-size: cover;
	}

	// @include bp("> 1024px") {
	// 	background-size: auto;
	// }
	//
	//
	.two-thirds {
		text-align: center;

		ul {
			@extend %reset-list;

			li {
				display: block;
				margin: 0 0 20px 0;
			}
		}
	}

	@include bp("> 800px") {
        .third,
        .two-thirds {
            margin-bottom: 0;
        }

		.third {
			width: 22%;
            margin-top: 20px;
            @include min(1100px) {
                margin-top: 50px;
            }
		}

		.two-thirds {
			width: 76%; 
			text-align: right;
            @include min(1100px) {
    			width: 70%; //73%
            }
            img {
                border-radius: 5px;
            }

			ul {
                display: flex;
                justify-content: center;

				li {
					display: inline-block;
					margin: 0 0 0 5%;
                    @include min(1200px) {
    					margin: 0 0 0 9.5%;
                    }

					&:first-child {
						margin-left: 0;
					}

					a {
						display: inline-block;
					}
				}
			}

            .ul-one {
				margin-top: 0;
                li {
					margin: 0 0 40px 5%;
                    @include min(1200px) {
    					margin: 0 0 40px 9.5%;
                    }
                }
            }
		}
	}
}

.third,
.two-thirds {
	margin-bottom: 20px;
}

@include bp("> 800px") {
	.third {
		float: left;
		width: 25%;
	}

	.two-thirds {
		float: right;
		width: 70%;
	}
}

#o-trebici {
	padding-bottom: 20px;

	.two-thirds {
		font-size: rem(21px);
		line-height: ( 32 / 20 );

		p {
			margin-bottom: 1em;
		}

		@include link {
			color: #d80000;
			text-decoration: underline;
		}

		@include link-over {
			text-decoration: none;
		}
	}

	.third p {
		margin: 8px 0 2em 0;
	}

	.otr-big {
		font-size: rem(75px);
		color: $color-main;
		line-height: 1;
		font-weight: bold;
	}
}


#nadpatou {
	background: #ededed;
	padding: 52px 0 30px 0;

	.box {
		@extend %border-box;
		@include grid(900px 3 2);
		margin-bottom: 25px;
	}

	@include bp("> 900px") {
		.centrovany {
			@include flex-base;
		}
	}
}

#obalpata {
	background-color: #ededed;
}

#pata {
	font-size: rem(16px);
	text-align: center;
	padding: 40px 0 20px 0;
	color: #5c5c5c;

	@include link {
		color: #5c5c5c;
	}

	.copy {
		color: #000000;
		font-weight: bold;
	}

	.webmaster,
	.inline {
		display: block;
		margin: 0 0 20px 0;
		padding: 0;
	}

	.webmaster {
		font-size: rem(14px);
	}
}

.strlistovani,
#listovani {
	img {
		background-color: #bbbbbb;
		color: #000000;
	}

	.aktivni {
		img {
			background-color: #555555 !important;
			color: #FFFFFF;
		}

		&:hover img,
		&:focus img {
			background-color: #bbbbbb !important;
			color: #000000;
		}
	}
}

.vip img {
	max-width: 170px;
}


#osobnost,
.utvarkontakt {
	.vip {
		width: 200px !important;
		padding-right: 0;
		text-align: right;

		img {
			max-width: 170px;
		}
	}

	#kvlevo {
		margin-right: 0 !important;
	}
}


@include bp("> 800px") {
	#osobnost {
		.vip {
			text-align: right;
		}

		.vip + #kvlevo {
		//	float: left;
			margin-right: 200px !important;
		}

		#kvlevo .editor {
			float: left;
		}
	}
}

// číslovaný seznam
.editor li ol{
	margin-left: 25px;

	li{
		list-style-type: decimal;
		list-style-image: none;
	}
}