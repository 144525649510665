/*****************************
* FORMULÁŘE
*****************************/

/*****************************
* DEFINICE PROMĚNNÝCH
*****************************/

$form-background: #eeeeee; // pozadí formulářů
$form-padding: 21px 36px 23px 36px; // odsazení formulářů
$form-text: #202020; // barva písma ve formuláři
$form-link: #202020; // barva odkazů ve formuláři
$format-height: 40px; // výška běžných inputů a selectů ve formulářích
$format-main-height: 50px; // výška hlavního inputu ve formuláři
$format-border: #c7c6c6; // barva okrajů inputů
$format-border-focus: darken(#c7c6c6, 22%); // barva okrajů inputů
$btn-height: 40px; // výška tlačítek ve formulářích
$btn-background: $color-dark; // pozadí tlačítek
$btn-border: 0; // border tlačítek
$btn-color: #ffffff; // barva textu tlačítek

/****************************/

/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/

%hiding-label {
   line-height: 1.2;
   text-align: left;
   padding: 3px 2px;
   display: block;

   &.label-skryty {
      padding: 0 2px;
      position: relative;

      &::after {
         @extend %fill;
         content: '';
         z-index: 2;
      }
   }
}

/****************************/

/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/

.format {
   @include transition(border-color 0.15s ease-out);
   background-color: #ffffff;
   color: #000000;
   height: $format-height;
   border-radius: 2px;
   line-height: (17 / 14);

   &:focus {
      border-color: $format-border-focus;
   }
}

.format,
fieldset {
   border: 2px $format-border solid;
}

:not(.fvlevo) > .fvyraz #hv {
   font-size: rem(19px);
   height: $format-main-height;
   border-width: 2px;
}

textarea.format {
   min-height: $format-height * 2;
}

select.format[multiple] {
   height: $format-height * 3;
}

.btn {
   background-color: $btn-background;
   border: $btn-border;
   color: $btn-color;
   height: $btn-height;
}

.fbtn .btn {
   padding: 0 2em;
   font-size: rem(16px);
   border-radius: 2px;
}

/************************************************/

.fkont {
   margin-top: 20px;

   form {
      padding: $form-padding;
   }

   :not(.fvlevo) > .fvyraz label[for='hv'] {
      font-size: rem(16px);
   }
}

.formular,
.fkont form,
.nastaveni {
   background-color: $form-background;
   color: $form-text;

   @include link {
      color: $form-link;
   }
}

//zjistit, kde to vůbec je kromě diskuze
.fcesta,
#{selector-link-all(".fcesta a")} {
   background-color: $th-background;
   color: $th-text;
}

/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/

.titulformular {
   label {
      @extend %hiding-label;

      &.label-skryty::after {
         background: transparent url(images/frm_prekryti.png) repeat 0 0;
      }
   }

   .format {
      margin: 0;
      padding: 3px 15px;
      width: 100%;
      background: {
         repeat: no-repeat;
         position: 0 0;
      }
   }

   .captchaformat {
      padding-right: 82px;
   }

   img {
      position: absolute;
      right: 2px;
      top: 2px;
      margin: 0;
   }

   .fbtn,
   .btn {
      clear: none;
      margin: 0;
   }

   .fbtn {
      text-align: center;
      margin-top: 30px;
   }

   .opiste {
      position: relative;
   }

   p {
      padding: 0 0 10px 0;
      margin: 0;
      line-height: (25 / 16);
   }
}

/***************
*	HLEDÁNÍ
***************/

#hledani {
   overflow: hidden;

   @include bp('> 500px') {
      float: right;
      width: 200px;

      .fixed-menu & {
         position: fixed;
         z-index: 55;
         right: 15px;
         top: 5px;
      }
   }

   .pole {
      position: relative;
      z-index: 5;
   }

   label {
      @extend %hiding-label;

      &.label-skryty::after {
         background: transparent url(images/hl_prekryti.png) repeat 0 0;
      }
   }

   input:focus {
      outline: 0;
   }

   .format {
      width: 100%;
      margin: 0;
      height: 33px;
      border-radius: 3px;
      text-indent: 0 !important;
      border-color: #ffffff !important;
      color: #000000;
      padding: 4px 40px 4px 10px !important;
      background: {
         repeat: no-repeat;
         position: 0 -30% !important;
      }
   }

   .btn {
      @extend %reset;
      border: 0;
      background-color: transparent;
      position: absolute;
      z-index: 2;
      top: 6px;
      right: 10px;
      height: auto;
      color: #000000;
   }
}

form.formular {
   label {
      font-size: 1rem;
   }

   .lblok label:first-child:last-child {
      position: relative;
      top: 11px;
   }

   .lblok [type='checkbox'] {
      margin-top: 0.25em;
      margin-left: 42px;
   }

   label + br + span {
      font-size: 0.9rem;
      font-style: italic;
   }

   .pblok {
      input[type='file'] {
         width: 100%;

         &::-webkit-file-upload-button {
            -webkit-appearance: none;
            appearance: none;
            background-color: $btn-background;
            border: $btn-border;
            color: $btn-color;
            height: 36px;
            box-sizing: border-box;
            padding: 2px 10px;
            margin-right: 15px;
         }
         &::file-selector-button {
            -webkit-appearance: none;
            appearance: none;
            background-color: $btn-background;
            border: $btn-border;
            color: $btn-color;
            height: 36px;
            box-sizing: border-box;
            padding: 2px 10px;
            margin-right: 15px;
         }
      }
   }

   .pblok:first-child {
      padding-left: 3%;

      > label:first-child {
         font-weight: bold;
      }
   }
}
