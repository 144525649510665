
/*************************
* SEZNAMY
*************************/

/*************************
* DEFINICE PROMĚNNÝCH
*************************/

$document-link-color: $color-main;    // barva odkazů ve výpisu dokumentů

$list-top-level-link-color: $color-main;  // barva velkých odkazů v první úrovni seznamu

$event-day-size: 22px;            // velikost písma v cedulce - den 
$event-month-size: 16px;          // velikost písma v cedulce - den 
$event-link-color: orange;        // barva odkazů ve výpisu akcí 
$event-day-background: gold;      // pozadí dne cedulky v kalendáři
$event-day-text: #101010;         // text dne cedulky v kalendáři
$event-month-background: #444444; // pozadí měsíce cedulky v kalendáři
$event-month-text: #ffffff;       // text měsíce cedulky v kalendáři

/************************/


/*************************
* MIXINY PRO ODRÁŽKY
*************************/

@mixin bullet($bg: #BCBCBC, $border: darken($bg, 20%), $top: .46em) {
	&::before {
		@extend %border-box;
		position: absolute;
		left: 4px;
		top: $top;
		display: inline-block;
		content: "";
		width: 7px;
		height: 7px;
		background: transparent encode-svg('<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="7px" height="7px" viewBox="0 0 7 7"><path fill="#{$bg}" stroke="#{$border}" stroke-miterlimit="10" d="M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z"/></svg>') no-repeat 0 0;
		line-height: 0;
		overflow: hidden;

		@content;
	}
}

@mixin no-bullet {
	&::before {
		content: normal !important;
	}
}

/************************/


li {
	.odkazy .ui & li,
	.aktuality2 &.bezobr  li,
	.utvary .ui &.o li,
	.kategorie .ui & li,
	.utvary .ui & li,
	.galerie .ui & li {
		@include bullet;
	}

	&.u,
	&.typsouboru {
		@include no-bullet;
		list-style-type: none!important;
	}

/* 	.ui & {
		.kategorie &,
		.utvary &,
		.galerie & {
			@include bullet;
		}
	}
 */
	.inline & {
		display: inline;
		list-style-type: none;

		&::before {
			position: relative;
			content: "\|";
			margin: 0 10px 0 11px;
		}

		&:first-child::before,
		&.skip::before,
		&.skip + li:not(.skip)::before {
			content: normal;
		}
	}
}

.ui {
	li {
		clear: none;
		padding: 0;
		margin: 5px 0 8px 0;

		strong {
			font-size: rem(23px);
			font-family: $font;
			font-weight: normal;
	
			#stranka & + div {
				padding: 0;
				color: #666666;

				@include bp("> 500px") {
					margin: 0 0 0 1.25em;
					display: inline;
				}
			}
		}

		@include link {
			color: $list-top-level-link-color;
		}

		ul {
			padding-top: 4px;

			ul {
				padding-top: 0;
			}
		}

		li {
			margin: 5px 0;
			padding-left: 22px;

			&::after {
				content: normal;
			}

			strong {
				font-family: $sans-serif;
				font-size: rem(16px);
			}

			@include link {
				color: #232323;
			}
		}
	}

	div {
		font-weight: normal;
		margin-bottom: 2px;
		margin-top: 6px;
		padding-top: 0;
		padding-bottom: 0;
	}
}


/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/

#isvs .hodnota {
	@extend .b2;

	li {
		@include no-bullet;
	}
}


/*********************
* VÝPISY DOKUMENTŮ
*********************/

.dok {
	ul {
		@extend %reset-list;
	}

	li {
		//@include no-bullet;
		display: block;
		margin: 20px 0;
		padding: 0;
		color: #444444;
	}

	.ktg {
		font-size: rem(15px);
	}

	strong {
		font-weight: normal;

		img {
			margin: 3px 20px 14px 0;
			float: left;

			@include bp("< 420px") {
				float: none;
				display: table;
				margin-right: 0;
			}
		}

		a {
			font-size: rem(23px);
			font-family: $font;
			color: $document-link-color;
			line-height: 1.1;
		}
	}
}

#zpravodajstvi {
	ul.inline {
		padding: 0;
		margin: 20px 0;
	}
}



/*******************************
* PŘEHLED OSOB
*******************************/

.dok.osoby {
	li {
		//padding-left: 146px;

		img {
			display: none;
			// margin-left: -146px;
			// max-width: 120px;
		}

		// &.noface {
		// 	&::before {
		// 		content: "";
		// 		margin-left: -146px;
		// 		float: left;
		// 		width: 120px;
		// 		height: 120px;
		// 		background: #cccccc url(/aspinclude/vismoweb5/html/noface.svg) no-repeat 50% 50%;
		// 		background-size: 80px auto;
		// 	}
		// }

		@include bp("< 420px") {
			padding-left: 0;

			strong img,
			&.noface::before {
				float: none;
				display: table;
				margin: 0 0 10px 0;
			}
		}
	}
}

/*******************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ	
*******************************/

.obrodkazy {
	.ui {
		@extend %reset-list;
	}
	
	@include bp("> 1024px") {
		&.socialni-site .ui {
			margin-left: 62px;
		}
	}

	li {
		@include no-bullet;
		@extend %reset;
		display: block;
		margin-bottom: 13px;
		background-image: none;
		position: relative;

		@include link {
			text-decoration: underline;
			color: #303030;
		}

		@include link-over {
			text-decoration: none;
		}
	}

	a {
		font-size: rem(21px);
		font-family: $font;
		background-repeat: no-repeat;
		background-position: 0 50%;
		padding-left: 60px;
		display: table;
		height: 1em;
		min-height: 48px;
	}

	.mtext {
		display: table-cell;
		vertical-align: middle;
	}

	strong {
		font-weight: normal;
	}
}


/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/

#vypisakci {
	h3.cvi {
		font-size: rem(25px);
		margin-bottom: 10px;
	}

	.dok {
		li {
			display: table;
			margin: 14px 0;
			padding-bottom: 14px;
			width: 100%;

			strong {
				@include link {
					color: $event-link-color;
				}
			}
		}

		.datum,
		.prazdnyDatum {
			display: table-cell;
			vertical-align: top;
			width: 5.52em;
			text-align: center;
			padding: .26em 0;
			font-family: $font;
		}

		.den,
		.mesic {
			display: block;
			line-height: 1.2;
		}

		.den {
			font-size: rem($event-day-size);
			font-weight: bold;
			background: $event-day-background;
			color: $event-day-text;
			padding: .3em 0;
		}

		.mesic {
			font-size: rem($event-month-size);
			background: $event-month-background;
			color: $event-month-text;
			text-transform: lowercase;
			padding: .5em 0;
		}

		.denPredlozka,
		.rok, 
		.den .tecka {
			position: absolute;
			left: -9999px;
			right: 9990px;
		}

		.prazdnyDatum {
			background: transparent;
		}

		.obsahAkce {
			display: table-cell;
			padding-right: 15px;
			padding-left: 15px;
		}
	}
}



/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/

.titulodkazy {
	.ui {
		@extend %reset-list;
	}

	li {
		@include no-bullet;
		display: block;
		background-image: none;
		margin: 4px 0;
		padding: 0;
		position: relative;

		div {
			margin: 2px 0 0 0;
		}
	}

	strong {
		font-weight: normal;
	}
}



.temata {
	background-color: #ededed;
	padding-top: 44px;
	padding-bottom: 44px;

	.ui {
		@extend %reset-list;

		li {
			@include grid(500px 2 2, 900px 4);

			strong {
				@include font-size(24px);
				font-weight: bold;
				font-family: $font;
				line-height: 1.15;
				display: block;

				+ div {
					display: none;
				}
	
				@include bp("> 500px") {
					+ div {
						color: #353535;
						display: block;
						margin-left: 0;
						margin-top: 6px;
					}
				}
			}

			@include link {
				color: $color-main;
				text-decoration: none;
			}

			@include link-over {
				color: $color-main;
				text-decoration: underline;
			}
		}
	}
}


.bodkazy {
	.ui li {
		padding-left: 0;

		&.typsouboru {
			padding-left: 32px;
			background-position: 0 .55em;
		}
	}
}

dl.kontakty {
	ul.ui ul {
		margin-left: 0;
	}
}

#nahore ul.ui li strong a,
dd ul.ui li strong a { 
	font-size: 19px;
}

dd ul.ui li li strong a { 
	font-size: 16px;
}

div#trdochazka{width:15px;float:left;margin:20px 6px 0 0;}
span.trdochazka,
ul#popisdochazky li::before{display:block;width:8px;height:8px;float:left;margin:4px 6px 0px 0px;padding:0;line-height:1%;font-size:12%;}
div#trdochazka.off,
span.trdochazka.off,
ul#popisdochazky li.nepritomen::before{background-color:red;}
div#trdochazka.on,
span.trdochazka.on,
ul#popisdochazky li.pritomen::before{background-color:green;}

ul#popisdochazky {
	padding-left: 0;
	margin-left: 0;
	list-style-type: none;

	li::before {
		content: "";
		margin-top: 6px;
	}
}

li span.trdochazka {
	border-radius: 50%;
	position: relative;
	top: 3em;
	display: inline-block;
}

.utvary li.o {
	@include no-bullet;
	padding-left: 0;

	.trdochazka {
		width: 11px;
		height: 11px;
		margin-right: 9px;
	}
}


.item--facebook,
.instaimg {
	.source {
		font-family: $font;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.item--facebook .source::before { 
	color: #3b5998;
	content: "Facebook";
}

.instaimg .source::before {
	color: #c13584;
	content: "Instagram";
}

.seznam td {
	word-break: break-word;

	@include msie-only {
		max-width: 12em;
		-ms-word-wrap: break-word;
	}
}